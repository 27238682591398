import React from "react";

// reactstrap components
import {useDispatch} from 'react-redux'
import Axios from 'axios'
import {cargarNotaria} from '../actions/notariaAction'
import {CodigoNotaria, UrlApi} from "../config.js"

// core components
import PresentationHeader from "components/Headers/PresentationHeader.js";

// sections for this page
import SectionSummary from "./presentation-sections/SectionSummary.js";
import SectionCards from "./presentation-sections/SectionCards.js";
import SectionExamples from "./presentation-sections/SectionExamples.js";
import SectionContactUs from "./sections-sections/SectionContactUs.js"


function Presentation() {

  const dispatch = useDispatch()
  
  React.useEffect(() => {
    
    const consultar = async () =>{

      const resultado = await Axios.get(UrlApi+'notaria/'+CodigoNotaria)

      if(resultado.status === 200){
        await dispatch(cargarNotaria(resultado.data.data))
      }
   }
   consultar()
  });
  
  document.documentElement.classList.remove("nav-open");
  const checkScroll = () => {
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("section-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    const updateView = () => {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 6) - 1;
        if (
          contentSections[i].offsetTop - window.innerHeight / 2 <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", updateView);
    };
  });
  return (
    <>
      <PresentationHeader />
      <SectionSummary />
      <SectionCards />
      <SectionExamples />
      <SectionContactUs />
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              data-number="1"
              href="#servicios"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("servicios").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#aplicativos"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("aplicativos").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
             
            </a>
          </li>
          <li>
            <a
              data-number="3"
              href="#oficinas"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("oficinas").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
            </a>
          </li>
          <li>
            <a
              data-number="4"
              href="#contact-us"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("contact-us").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Presentation;
