import React from "react";

// reactstrap components
import {
  Container

} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function MisionVision() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "12em" }}>
        <div className="section">
          <Container>
            <h3 className="title" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Misión:
            </h3>
            <p className="my-4" style={{ textAlign: "justify", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
              Somos una entidad prestadora de servicios públicos notariales, enfocada en
              desarrollar estrategias de mejoramiento que contribuyan de manera eficaz en la
              satisfacción de las necesidades notariales de los usuarios, basados en la ley y en
              elevados principios éticos, ofrecemos asesoría oportuna, segura, cordial y eficiente en las
              actuaciones jurídicas que nos competen. en donde la seguridad jurídica, la tecnología, la 
              calidad y la agilidad constituyen la base de la nuestra excelencia.
            </p>
            <h3 className="title" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Visión:
            </h3>
            <p className="my-4" style={{ textAlign: "justify", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
              Seremos una notaría reconocida a nivel nacional por la excelente prestación de
              los servicios públicos notariales, con un alto nivel de seguridad jurídica, excelente
              infraestructura física, implementando herramientas tecnológicas y capacitando
              permanentemente a nuestro personal con el fin de garantizar el cumplimiento de los
              objetivos y políticas de la notaria.
            </p>
          </Container>
        </div>
      </div>
    </>
  );
}

export default MisionVision;
