import React , { useState } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  NavbarToggler,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Funciones from "../../views/sections-sections/Funciones.js";

function ColorNavbar(props) {

  const [isOpen, setIsOpen] = useState(false);

  const toggle2 = () => setIsOpen(!isOpen);
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const[modal4,setModal4] = React.useState(false);
  
  
  
  return (
    <>
    
    {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Row>
	      <Col md="12">
	        <Navbar 
            className={classnames("fixed-top")}
            expand="lg"
            id="navbar-main"
          >
		        <Container>
              <div className="navbar-translate">
                <NavbarBrand  id="navbar-brand" to="/" tag={Link}>
                  <img
                    alt="Logo de la Notaria 10 bucaramanga"
                    className="img"
                    width="160"                    
                    src={require("assets/img/sections/LogoNotaria.png")}
                  />
                </NavbarBrand>
                <button
                  className="navbar-toggler"
                  style={{marginRight:'20px'}}
                  id="navigation"
                  type="button"
                  onClick={() => {
                    document.documentElement.classList.toggle("nav-open");
                    setBodyClick(true);
                    setCollapseOpen(true);
                  }}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarToggler onClick={toggle2} />
              <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar >
                <NavItem>
                    <NavLink
                      to="/" tag={Link}
                    >
                     
                      Inicio
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar >
                    <DropdownToggle className="mr-2" color="default" caret nav >
                      

                      SERVICIOS NOTARIALES
                    </DropdownToggle>
                    <DropdownMenu  className="dropdown-danger" end style={{position:'absolute', height:'auto'}}>
                      <DropdownItem to="/cotizador" tag={Link}>Cotizador</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2" color="default" caret nav >
                      Conócenos
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" end style={{'position':'absolute', 'height':'auto'}}>
                    <DropdownItem to="/MisionVision" tag={Link} >Misión y Visión</DropdownItem>
                    <DropdownItem onClick={() => setModal4(true)} >Funciones y Deberes</DropdownItem>
                    {/* <DropdownItem to="#" tag={Link} >Procesos y Procedimientos</DropdownItem> */}
                    <DropdownItem to="/Organigrama" tag={Link} >Organigrama</DropdownItem>
                    <DropdownItem to="/Notario" tag={Link} >Notario</DropdownItem>
                      
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar >
                    <DropdownToggle className="mr-2" color="default" caret nav >
                      Tramites
                    </DropdownToggle>
                    <DropdownMenu  className="dropdown-danger" end style={{'position':'absolute', 'height':'auto'}}>
                      <DropdownItem to="/InfoTramites" tag={Link}>Información</DropdownItem>
                      <DropdownItem to="/tramites" tag={Link}>Servicios</DropdownItem>
                      <DropdownItem to="/Tarifas" tag={Link}>Tarifas</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink
                      to="/#contact-us" tag={Link}
                    >
                     
                      Contáctenos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/Transparencia/informacionPublica" tag={Link}
                    >
                      Transparencia
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/participa" tag={Link}
                    >
                      Participa
                    </NavLink>
                  </NavItem>
                  
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
          <Funciones modal={modal4} setModal={() => setModal4(false)} />
        </Col>
      </Row>
    </>
  );
}

export default ColorNavbar;
