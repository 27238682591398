import React from "react";

// reactstrap components
import { Container, Row, Col, Button, Modal } from "reactstrap";
// core components

function SectionSummary() {

  const [escritura, setEscritura] = React.useState(false);
  const [civil, setCivil] = React.useState(false);
  const [notice, setNotice] = React.useState(false);

  return (
    <>
      <div
          className="features-5 section-image "
          id="servicios"
        >
          <Container>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info" id="servicios">
                  <div className="icon">
                  <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
                  </div>
                  <h4 className="title">Escrituración</h4>
                  <p>
                  Documento que contiene la declaración de voluntad de una o varias personas, emitidas ante el notario, para hacer un determinado contrato o un acto jurídico individual.
                  </p>
                  <div className="mt-4">
                    
                    <Button
                    style={{paddingLeft:'5px'}}
                  className="btn-just-icon mr-1"
                  color="success"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setEscritura(true);
                  }}
                >
                   Ver
                </Button>
                  </div>
                  {/* MODALS */}
                  <Modal isOpen={escritura} toggle={() => setEscritura(false)} >
                    <div className="modal-header">
                      <button
                      className="close"
                      type="button"
                      onClick={() => setEscritura(false)}
                      >
                      <span>×</span>
                      </button>
                      <h5
                      className="modal-title text-center font-weight-bold"
                      id="exampleModalLabel"
                      >
                      <span className="icon pr-3" >
                      <i aria-hidden={true} className="nc-icon nc-ruler-pencil font-weight-bold" style={{fontSize:'30px'}}/>
                        
                      </span>
                      Requisitos
                      </h5>
                    </div>
                    <div className="modal-body font-weight-normal " >
                      <ul className="list-unstyled" style={{fontSize:'16px'}}> 
                        <li> <i className="fa fa-circle  pr-4 pb-2 iconRequest" ></i> Asistir personalmente a la notaria </li>
                        <li> <i className="fa fa-circle  pr-4 pb-2 iconRequest"></i> Llevar cédula de ciudadania</li>
                        <li> <i className="fa fa-circle  pr-4 pb-2 iconRequest"></i> Llevar documento privado que se va a firmar</li>
                        <li> <i className="fa fa-circle  pr-4 pb-2 iconRequest"></i> Llevar testigo cuando asi se requiera</li>
                      </ul>
                    </div>
                    <div className="modal-footer " >
                      <Button
                      className="btn-link font-weight-bold "
                      type="button"
                      color="success"
                      onClick={() => setEscritura(false)}
                      >
                      <span  >Ok</span>
                      </Button>
                    </div>
                  </Modal>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-single-copy-04" />
                  </div>
                  <h4 className="title">Registro Civil</h4>
                  <p>
                  Es un documento indispensable mediante el cual la persona prueba ante la familia y la sociedad su existencia y le permite identificarse, ejercer sus derechos y cumplir sus obligaciones.
                  </p>
                  <div className="mt-4">
                    
                    <Button
                    style={{paddingLeft:'5px'}}
                  className="btn-just-icon mr-1"
                  color="success"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setCivil(true);
                  }}
                >
                   Ver
                </Button>
                  </div>
                  {/* MODALS */}
                  <Modal isOpen={civil} toggle={() => setCivil(false)} >
                    <div className="modal-header">
                      <button
                      className="close"
                      type="button"
                      onClick={() => setCivil(false)}
                      >
                      <span>×</span>
                      </button>
                      <h5
                      className="modal-title text-center font-weight-bold"
                      id="exampleModalLabel"
                      >
                      <span className="icon pr-3">
                      <i aria-hidden={true} className="nc-icon nc-single-copy-04 font-weight-bold"  style={{fontSize:'30px'}}/>
                      
                      
                      </span>
                      Requisitos
                      </h5>
                    </div>
                    <div className="modal-body font-weight-normal ">
                      <ul className="list-unstyled" style={{fontSize:'16px'}}>
                        <li><i className="fa fa-circle  pr-4 pb-2 iconRequest  "></i>Certificado de nacido vivo</li>
                        <li><i className="fa fa-circle  pr-4 pb-2 iconRequest  "></i>Documentos de identificación de los padres</li>
                      </ul>
                      <p  style={{fontSize:'16px'}} className="font-weight-normal">La inscripción de registro civil no tiene costo.</p>
                    </div>
                    <div className="modal-footer " >
                      <Button
                      className="btn-link font-weight-bold "
                      color="success"
                      type="button"
                      onClick={() => setCivil(false)}
                      >
                      <span >Ok</span>
                      </Button>
                    </div>
                  </Modal>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line text-center">
              <Col className="ml-auto mr-auto" sm="5" md="12">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-paper" />
                  </div>
                  <h4 className="title">Autenticaciones</h4>
                  <p>
                  Es cuando el notario da testimonio escrito de las firma que aparecen en un documento privado fueron puestas en su presencia, estableciendo la identidad de los firmantes.
                  </p>
                  <div className="mt-4">
                    
                    <Button
                    style={{paddingLeft:'5px'}}
                  className="btn-just-icon mr-1"
                  color="success"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setNotice(true);
                  }}
                >
                   Ver
                </Button>
                  </div>
                  {/* MODALS */}
                  <Modal isOpen={notice} toggle={() => setNotice(false)} >
                    <div className="modal-header">
                      <button
                      className="close"
                      type="button"
                      onClick={() => setNotice(false)}
                      >
                      <span>×</span>
                      </button>
                      <h5
                      className="modal-title text-center font-weight-bold"
                      id="exampleModalLabel"
                      >
                      <span className="icon pr-3" >
                        <i className="nc-icon nc-paper font-weight-bold" style={{fontSize:'30px'}}/>  
                      </span>
                      Requisitos
                      </h5>
                    </div>
                    <div className="modal-body font-weight-normal ">
                      <ul className="list-unstyled" style={{fontSize:'16px'}}>
                      <li> <i className="fa fa-circle  pr-4 pb-2 iconRequest pr-3 "></i> Asistir personalmente a la notaría. Puede  </li>
                      <span className="ml-4 pb-2">hacerse también a domicilio </span>
                      <li><i className="fa fa-circle  pr-4 pb-2 iconRequest pr-4 pb-2 "></i>Llevar cédula de ciudadania </li>
                      <li><i className="fa fa-circle  pr-4 pb-2 iconRequest pr-4 pb-2 "></i>Llevar el documento que se va a autenticar </li>
                      </ul>
                    </div>
                    <div className="modal-footer " >
                      <Button
                      className="btn-link font-weight-bold "
                      color="success"
                      type="button"
                      onClick={() => setNotice(false)}
                      >
                      <span style={{fontSize:'14px'}}>Ok</span>
                      </Button>
                    </div>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </>
  );
}

export default SectionSummary;
