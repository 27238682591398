/*eslint-disable*/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card
} from "reactstrap";

import Funciones from "../../views/sections-sections/Funciones.js";

// core components

function SectionTransparencia() {
  let navigate = useNavigate();
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [modal4, setModal4] = React.useState(false);
  const [modal5, setModal5] = React.useState(false);
  const [modal6, setModal6] = React.useState(false);
  const [modal7, setModal7] = React.useState(false);
  const [modal8, setModal8] = React.useState(false);
  const [modal9, setModal9] = React.useState(false);
  const [modal10, setModal10] = React.useState(false);
  const [modal15, setModal15] = React.useState(false);
  const [mecanismosContacto, setmecanismosContacto] = React.useState(false);
  const [informacionInteres, setinformacionInteres] = React.useState(false);
  const [talentoHumano, setTalentoHumano] = React.useState(false);
  const [normativa, setnormativa] = React.useState(false);
  const [mecanismoControl, setmecanismoControl] = React.useState(false);
  const [tramites, setTramites] = React.useState(false);
  const [instrumentos, setinstrumentos] = React.useState(false);
  const [transparencia, settransparencia] = React.useState(false);
  const [web, setweb] = React.useState(false);
  const [proteccion, setproteccion] = React.useState(false);
  const [activePill, setActivePill] = React.useState("1");

  let { modalNombre } = useParams();
  //Funciones para reemplazar la url cuando se abra un modal en especifico
  const handleModal1 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/sitioEnConstruccion' });
      setModal1(true);
    } else {
      history.replace({ pathname: '/transparencia/informacionPublica' });
      setModal1(false);
    }
  }

  const handleModal4 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/funcionesYdeberes' });
      setModal4(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal4(false);
    }
  }

  const handleModal5 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/directorioAgremiaciones' });
      setModal5(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal5(false);
    }
  }

  const handleModal6 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/informesGestion' });
      setModal6(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal6(false);
    }
  }

  const handleModal7 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/mecanismosSupervicion' });
      setModal7(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal7(false);
    }
  }

  const handleModal10 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/entidadesSector' });
      setModal10(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal10(false);
    }
  }

  const cerrar1 = () => {
    setmecanismosContacto(false);
    setinformacionInteres(false);
    setTalentoHumano(false);
    setnormativa(false);
    setmecanismoControl(false);
    setTramites(false);
    setinstrumentos(false);
    settransparencia(false);
    setweb(false);
    setproteccion(false);
  };


  useEffect(() => {
    if (modalNombre) {
      switch (modalNombre) {
        case 'sitioEnConstruccion':
          // En construcción
          setModal1(true);
          break;
        case 'directorioAgremiaciones':
          // Directorio de agremiaciones
          setModal5(true);
          setmecanismosContacto(true)
          break;
        case 'informesGestion':
          // informes de gestión
          setModal6(true);
          setTalentoHumano(true);
          break;
        case 'mecanismosSupervicion':
          // Mecanismos de supervición
          setModal7(true);
          break;
        case 'entidadesSector':
          // entidades del sector
          setModal10(true);
          setmecanismosContacto(true)
          break;
        case 'funcionesYdeberes':
          // funciones y deberes
          setmecanismosContacto(true)
          setModal4(true);
          break;
        case '0':
          history.replace({ pathname: '/transparencia/informacionPublica' })
          break;
        default:
          break;
      }
    }
  }, [])


  return (
    <>
      <div
        className="section section-project cd-section"
        style={{ paddingTop: "100px", paddingBottom: "30px" }}
        id="projects"
      >
        <div className="projects-2 section section-dark">
          <Container style={{ marginTop: "10em" }}>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h6 className="text-muted"></h6>
                <h2 className="title">
                  Transparencia y acceso a información pública
                </h2>

                <h5 className="description">

                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <p>
                  <button
                    className="btn btn-success btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    onClick={() => {
                      cerrar1(), setmecanismosContacto(true);
                    }}
                    style={{
                      padding: "21px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "43px" }}>
                        <i className="fa fa-user-circle" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        1. INFORMACIÓN DE LA ENTIDAD
                      </span>
                    </Col>
                  </button>
                </p>
                {mecanismosContacto && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                          <Row>
                            <Col className="col-12">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.1. Misión, Visión, Funciones y deberes.
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/MisionVision"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.1.a. Misión y Visión
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal4(true)}
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.1.b. Funciones y deberes
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.2. Estructura orgánica - organigrama
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/Organigrama"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.2.a. Organigrama
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.3. Mapas y cartas descriptivas de los procesos
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/MapaProcesos"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.3.a. Mapas y cartas descriptivas de los procesos
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.4. Directorio Institucional incluyendo sedes, oficinas, sucursales, o regionales, y dependencias.
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/#contact-us"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.4.a. Información de contacto
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/#contact-us"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.4.b. Ubicación física (nombre de la sede si aplica).
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/#contact-us"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    "1.4.c. Dirección  (incluyendo el departamento si aplica) y municipio o distrito (en caso que aplique,
                                    se deberá indicar el nombre del corregimiento)."
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/#contact-us"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.4.d. Horarios y días de atención al público.
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    href="/#contact-us"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.4.e. Datos de contacto específicos de las áreas de contacto o dependencias (en caso de que aplique).
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.5 Directorio de servidores públicos, empleados o contratistas.
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal10(true)}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.5.1. Listado de entidades que integran el sector.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal10(true)}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.6. Directorio de entidades.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal10(true)}
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.6.1. Directorio de entidades.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal5(true)}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.7. Directorio de agremiaciones, asociaciones y otros grupos de interés.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal5(true)}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer',
                                    }}
                                  >
                                    1.7.1. Directorio de agremiaciones, asociaciones y otros grupos de interés.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.8 Servicio al público, normas, formularios y protocolos de atención.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal1(true)}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer',
                                    }}
                                  >
                                    1.8.1. Servicio al público, normas, formularios y protocolos de atención.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    href="/Normativa"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.8.2. Normas.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    href="/login"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.8.3. Formularios.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    href="/Protocolo"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.8.4. Protocolos de Atención.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.9 Procedimientos que se siguen para tomar decisiones en las diferentes áreas.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    href="/Decisiones"
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.9.1 Procedimientos que se siguen para tomar decisiones en las diferentes áreas
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a

                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    "1.10. Mecanismo de presentación directa de solicitudes, quejas y reclamos a disposición
                                    del público en relación con acciones u omisiones del sujeto obligado."
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    href="/LoginPage"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.10.1. Mecanismo de presentación directa de solicitudes, quejas y reclamos.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.12 Información sobre decisiones que pueden afectar al público.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    href="/AfectarPublico"
                                    tag={Link}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif"
                                    }}
                                  >
                                    1.12.1. Información sobre decisiones que puede afectar al público.
                                  </a>
                                </li>

                                <li className="list-group-item">
                                  <a
                                    onClick={() => handleModal7(true)}
                                    style={{
                                      fontWeight: 500,
                                      fontFamily: "'Noto Sans SC', sans-serif",
                                      cursor: 'pointer'
                                    }}
                                  >
                                    1.13 Entes y autoridades que lo vigilan.
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse2"
                    aria-expanded="false"
                    aria-controls="collapse2"
                    onClick={() => {
                      cerrar1(), setinformacionInteres(true);
                    }}
                    style={{
                      padding: "21px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "0px" }}>
                        <i className="fa fa-info" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        2. NORMATIVA
                      </span>
                    </Col>
                  </button>
                </p>
                {informacionInteres && (
                  <Row >
                    <Col className="col-12" >
                      <div className="collapse" id="collapse2">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                            >
                              2.1. Normativa de la entidad o autoridad.
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/Normativa"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                2.1.1. Leyes.
                              </a>
                            </li>

                            <li
                              className="list-group-item"
                            >
                              <a
                                href="/Normativa"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                2.1.2. Decreto Único Reglamentario.
                              </a>
                            </li>

                            <li
                              className="list-group-item"
                            >
                              <a
                                href="/Normativa"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                2.1.3. Normativa aplicable.
                              </a>
                            </li>

                            <li className="list-group-item">
                              <a
                                href="http://svrpubindc.imprenta.gov.co/diario/"
                                target="_black"
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: "pointer"
                                }}
                              >
                                2.1.4. Vínculo al Diario o Gaceta Oficial.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/#aplicativos"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                2.1.5. Políticas, lineamientos y manuales.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                2.2. Búsqueda de normas.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="https://www.suin-juriscol.gov.co/"
                                target="_black"
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                2.2.1. Sistema Único de Información Normativa - SUIN.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapse3"
                    onClick={() => {
                      cerrar1(), setTalentoHumano(true);
                    }}
                    style={{
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "43px" }}>
                        <i className="fa fa-sitemap" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        4. PLANEACIÓN
                      </span>
                    </Col>
                  </button>
                </p>
                {talentoHumano && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse3">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                4.7 Informes de gestión, evaluación y auditoría.
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              onClick={() => handleModal6(true)}
                              style={{ cursor: "pointer" }}
                            >
                              4.7.4. Informes a organismos de inspección, vigilancia y control.
                            </li>

                            <li
                              className="list-group-item"
                              style={{ cursor: "pointer" }}
                            >
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: "pointer"
                                }}
                              >
                                4.7.5. Planes de mejoramiento:
                              </a>
                            </li>

                            <li
                              className="list-group-item"
                              style={{ cursor: "pointer" }}
                            >
                              <a
                                href="https://www.supernotariado.gov.co/transparencia/planeacion-presupuesto-e-informes/auditorias-de-gestion-calidad-e-integrales/"
                                target="_black"
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: "pointer"
                                }}
                              >
                                4.7.5.b. Enlace al organismo de control donde se encuentren los informes que éste ha elaborado
                                en relación con el sujeto obligado.
                              </a>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <div className="space-top" />

            <Row>
              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapse4"
                    onClick={() => {
                      cerrar1(), setnormativa(true);
                    }}
                    style={{
                      padding: "21px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "0px" }}>
                        <i className="fa fa-shield" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>5. TRÁMITES</span>
                    </Col>
                  </button>
                </p>
                {normativa && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse4">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                5.1 Informes de gestión, evaluación y auditoría.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/Normativa"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                5.1.1 .Normatividad que sustenta el trámite.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/procesos"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                5.1.2. Procesos.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/Tarifas"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                5.1.3. Costos asociados.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/InfoTramites"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                5.1.4. Formatos y/o formularios asociados.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapse7"
                    onClick={() => {
                      cerrar1(), setmecanismoControl(true);
                    }}
                    style={{
                      padding: "21px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "43px" }}>
                        <i className="fa fa-retweet" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        6. PARTICIPA
                      </span>
                    </Col>
                  </button>
                </p>
                {mecanismoControl && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse7">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >

                            
                            <li className="list-group-item">
                              <a
                                href="/participa"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                6.1 Descripción General.
                              </a>
                            </li>

                            <li className="list-group-item">
                              <a
                                href="/participa"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                6.2 Estructura y Secciones del menú "PARTICIPA".
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse9"
                    aria-expanded="false"
                    aria-controls="collapse9"
                    onClick={() => {
                      cerrar1(), setTramites(true);
                    }}
                    style={{
                      padding: "21px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "36px" }}>
                        <i className="fa fa-file-text-o" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        7. DATOS ABIERTOS
                      </span>
                    </Col>
                  </button>
                </p>
                {tramites && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse9">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                }}
                              >
                                7.1 Instrumentos de gestión de la información.
                              </a>
                            </li>

                            <li className="list-group-item">
                              <a
                                href="/GestionInformacion"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                7.1.1 Registros de activos de información
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/ClasificadaReservada"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                7.1.2 Índice de información clasificada y reservada.
                              </a>
                            </li>

                            <li className="list-group-item">
                              <a
                                href="/GestionInformacion"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                7.1.3 Esquema de publicación de la información
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/GestionDocumental"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                7.1.4 Programa de gestión documental.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/RetencionDocumental"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                7.1.5. Tablas de retención documental.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                7.2 Sección de Datos Abiertos
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="http://datos.gov.co/"
                                target="_black"
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                7.2.1 Datos Abiertos
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse10"
                    aria-expanded="false"
                    aria-controls="collapse10"
                    onClick={() => {
                      cerrar1(), setinstrumentos(true);
                    }}
                    style={{
                      padding: "7px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "43px" }}>
                        <i className="fa fa-wrench" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        8. INFORMACIÓN ESPECÍFICA PARA GRUPOS DE INTERÉS.
                      </span>
                    </Col>
                  </button>
                </p>
                {instrumentos && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse10">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                8.1. Información para Grupos Específicos.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/Ninos"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: "pointer"
                                }}
                              >
                                8.1.1. Información para niños, niñas y adolescentes.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                className="list-group-item"
                                href="/Mujeres"
                                tag={Link}
                                style={{ 
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: "pointer" }}
                                >
                                8.1.2. Informacion para Mujeres.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                className="list-group-item"
                                // onClick={() => handleModal1(true)}
                                href="/Otrosin"
                                tag={Link}
                                style={{ 
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: "pointer" }}
                              >
                                8.1.3. Otros de grupos de interés.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapse11"
                    onClick={() => {
                      cerrar1(), settransparencia(true);
                    }}
                    style={{
                      padding: "20px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "43px" }}>
                        <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12" style={{ paddingLeft: "0px" }}>
                      <span style={{ fontSize: "15px" }}>
                        9. OBLIGACIÓN DE REPORTE DE INFORMACIÓN ESPECÍFICA POR PARTE DE LA ENTIDAD
                      </span>
                    </Col>
                  </button>
                </p>
                {transparencia && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse11">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              onClick={() => handleModal1(true)}
                              style={{ cursor: "pointer" }}
                            > 
                              <a
                                className="list-group-item"
                                // onClick={() => handleModal1(true)}
                                href="/normativa"
                                tag={Link}
                              >
                                9.1. Normatividad Especial.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col md="4">
                <p>
                  <button
                    className="btn btn-primary btn-large btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse12"
                    aria-expanded="false"
                    aria-controls="collapse12"
                    onClick={() => {
                      cerrar1(), setweb(true);
                    }}
                    style={{
                      padding: "21px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "36px" }}>
                        <i className="fa fa-hand-o-up" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        ATENCIÓN Y SERVICIOS A LA CIUDADANÍA
                      </span>
                    </Col>
                  </button>
                </p>
                {web && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse12">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer'
                                }}
                              >
                                1. Trámites, Otros Procedimientos Administrativos y consultas de acceso a información pública.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                2. Canales de atención y pida una cita.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/loginpage"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                2.1. Canales habilitados para la atención a la ciudadanía
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/#contact-us"
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer',
                                }}
                              >
                                2.2. Mecanismo para que el usuario pueda agendar una cita para atención presencial, e indicar los
                                horarios de atención en sedes físicas
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <div className="space-top" />
            <Row>
              <Col md="12" className="text-center">
                <p>
                  <button
                    className="btn btn-primary btn-large"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse13"
                    aria-expanded="false"
                    aria-controls="collapse13"
                    onClick={() => {
                      cerrar1(), setproteccion(true);
                    }}
                    style={{
                      padding: "34px",
                      color: "white",
                      borderColor: "#2C850B",
                      backgroundColor: "#2C850B"
                    }}
                  >
                    <Col className="col-12">
                      <span style={{ fontSize: "6em", paddingRight: "36px" }}>
                        <i className="fa fa-folder-open-o" aria-hidden="true"></i>
                      </span>
                    </Col>
                    <Col className="col-12">
                      <span style={{ fontSize: "15px" }}>
                        NOTICIAS
                      </span>
                    </Col>
                  </button>
                </p>
                {proteccion && (
                  <Row>
                    <Col className="col-12">
                      <div className="collapse" id="collapse13">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li className="list-group-item">
                              <a
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                12.1. Sección de Noticias.
                              </a>
                            </li>
                            <li className="list-group-item">
                              <a
                                href="/#aplicativos"
                                tag={Link}
                                style={{
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                12.1.1. Sección de noticias.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
          <Funciones modal={modal4} setModal={() => handleModal4(false)} />
        </div>
      </div >

      {/* MODALES */}

      {/* EN CONSTRUCCIÓN */}
      <Modal
        isOpen={modal1}
        toggle={() => handleModal1(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => handleModal1(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Página Web en construcción
          </h5>
        </div>
        <div className="modal-body">
          <img
            alt="Obrero animado mencionando que la página se encuentra en contrucción"
            className="img-rounded img-responsive"
            src={require("assets/img/sections/construccion.jpg")}
          />
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => handleModal1(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Informes de gestión, evaluación y auditoría */}

      <Modal
        isOpen={modal6}
        toggle={() => handleModal6(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => handleModal6(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Informes de gestión, evaluación y auditoría
          </h5>
        </div>
        <div className="modal-body">
          <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
            "Los informes que presentan los Señores
            Notarios a la Superintendencia de Notariado y
            Registro son:
          </p>
          <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
            Informe Estadistico Notarial, cuya información
            financiera y contable es reservada. Artículo
            61 del Código de Comercio. El informe
            estadistico que remiten mensualmente las
            Notarías a la SNR a través de los correos
            habilitados para ello, según la Instrucción
            Administrativa 4 de 2019.
          </p>
          <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
            Los informes que rinden los Señores Notarios a
            la UIAF son:
          </p>

          <ul style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
            <li>Reporte de Operación Notarial (RON).</li>
            <li>
              Reporte de Operaciones intentadas y
              operaciones sospechosas ROS.
            </li>
            <li>
              Reporte de ausencia de operaciones
              sospechosos
            </li>
            <li>Reporte de transacciones en efectivo.</li>
            <li>
              Reporte de ausencia de transacciones en
              efectivo.
            </li>
            <li>Reporte de adjudicaciones en remate.</li>
          </ul>
          <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
            Los informes que allegan los Señores Notarios
            a la DIAN son:
          </p>

          <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>Información exogena."</p>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => handleModal6(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Entes de Control que vigilan a la entidad y mecanismos de supervisión */}

      <Modal
        isOpen={modal7}
        toggle={() => handleModal7(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => handleModal7(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Entes de control que vigilan a la entidad y
            mecanismos de supervisión
          </h5>
        </div>
        <div className="modal-body">
          <div style={{ fontWeight: 500 }}>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              La orientación, inspección, vigilancia y
              control a las Notarías lo ejerce la
              Superintendencia de Notariado y Registro en
              virtud del artículo 4 del Decreto 2723 de
              2014.
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              La Superintendencia de Notariado y Registro
              ejerce el control conforme a los informes
              estadisticos que rinden mensualmente los
              Notarios, así como con ocasión de las
              visitas previstas en los artículos
              2.2.6.1.6.3.1 y siguientes del Decreto 1069
              de 2015.
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              El control que ejerce la Superintendencia de
              Notariado y Registro sobre las Notarias es
              de naturaleza administrativa y
              disciplinaria.
            </p>

            <br />
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Sede Principal y Atención al Ciudadano
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Calle 26 No. 13-49 Interior 201, Bogotá D.C. Colombia.
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Código postal : # 110311 – 110311000
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Nit: 899.999.007-0
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Horario de Atención : 08:00 a.m a 05:00 p.m.
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Telefono – Conmutador : 57+(1) 328 2121
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Conmutador Atención al Ciudadano : 57+(601) 328 2121
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Correo Institucional : correspondencia@supernotariado.gov.co
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              Sitio Web :  https://www.supernotariado.gov.co/
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => handleModal7(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Información para la problación vulnerable */}
      <Modal
        isOpen={modal15}
        toggle={() => setModal15(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal15(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Información para población vulnerable
          </h5>
        </div>
        <div className="modal-body">
          <div style={{ fontWeight: 500 }}>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              * La Ley 1997 de 2019 que permite la inscripción en el registro civil de los hijos de los nacionales venezolanos.
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              * La declaración extraproceso rendida por la mujer cabeza de familia como un acto exento de derechos notariales.
            </p>
            <p style={{ fontFamily: "'Noto Sans SC', sans-serif" }}>
              * El otorgamiento de la escritura pública para el cambio de nombre y para la corrección de errores u omisiones en el Registro del Estado Civil de miembros de comunidades indigenas."
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => setModal15(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Informe de Peticiones, quejas, reclamos,
            denuncias y solicitudes de acceso a la
            información */}

      <Modal
        isOpen={modal8}
        toggle={() => setModal8(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal8(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Informe de Peticiones, quejas, reclamos,
            denuncias y solicitudes de acceso a la
            información
          </h5>
        </div>
        <div className="modal-body">
          <img
            alt="Obrero animado mencionando que la página se encuentra en contrucción"
            className="img-rounded img-responsive"
            src={require("assets/img/sections/construccion.jpg")}
          />
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => setModal8(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Medios de seguimiento para la consulta del
            estado de las solicitudes de información
            pública */}

      <Modal
        isOpen={modal9}
        toggle={() => setModal9(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal9(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Medios de seguimiento para la consulta del
            estado de las solicitudes de información
            pública
          </h5>
        </div>
        <Row className="mt-4">
          <Col className="col-12 text-center">
            <img
              alt="Obrero animado mencionando que la página se encuentra en contrucción"
              className="img-rounded img-responsive"
              src={require("assets/img/sections/construccion.jpg")}
            />
          </Col>
        </Row>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => setModal9(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Listado de entidades que integral el sector */}

      <Modal
        isOpen={modal10}
        toggle={() => handleModal10(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => handleModal10(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Listado de entidades que integran el sector
          </h5>
        </div>
        <div className="modal-body">
          <div style={{
            marginTop: '15px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderRadius: '5px',
            paddingTop: '15px',
            paddingBottom: '15px'
          }}>
            <h6
              className="text-center"
              id="nameMinisterio"
            >
              Ministerio de Justicia y del Derecho
            </h6>
            <br />
            <div style={{ textAlign: 'center' }}>
              <a className="btn btn-secondary" href="https://www.minjusticia.gov.co/" role="button" target="_black">
                Ir a página
              </a>
            </div>
          </div>
          <div style={{
            marginTop: '15px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderRadius: '5px',
            paddingTop: '15px',
            paddingBottom: '15px'
          }}>
            <h6
              className="text-center"
              id="nameSuperIntendencia"
            >
              Superintendecia de Notariado y Registro
            </h6>
            <br />
            <div style={{ textAlign: 'center' }}>
              <a className="btn btn-secondary" href="https://www.supernotariado.gov.co/" role="button" target="_black">
                Ir a página
              </a>
            </div>
          </div>
          <div style={{
            marginTop: '15px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderRadius: '5px',
            paddingTop: '15px',
            paddingBottom: '15px'
          }}>
            <h6
              className="text-center"
              id="nameRegistraduria"
            >
              Registraduría Nacional de Estado Civil
            </h6>
            <br />
            <div style={{ textAlign: 'center' }}>
              <a className="btn btn-secondary" href="https://www.registraduria.gov.co/" role="button" target="_black">
                Ir a página
              </a>
            </div>
          </div>
          <div style={{
            marginTop: '15px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderRadius: '5px',
            paddingTop: '15px',
            paddingBottom: '15px'
          }}>
            <h6
              className="text-center"
              id="nameProcuraduria"
            >
              Procuraduría General de la Nación
            </h6>
            <br />
            <div style={{ textAlign: 'center' }}>
              <a className="btn btn-secondary" href="https://www.procuraduria.gov.co/portal/" role="button" target="_black">
                Ir a página
              </a>
            </div>
          </div>
          <div style={{
            marginTop: '15px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderRadius: '5px',
            paddingTop: '15px',
            paddingBottom: '15px'
          }}>
            <h6
              className="text-center"
              id="nameVentanilla"
            >
              Ventanilla Única de Registro VUR
            </h6>
            <br />
            <div style={{ textAlign: 'center' }}>
              <a className="btn btn-secondary" href="https://www.vur.gov.co/" role="button" target="_black">
                Ir a página
              </a>
            </div>
          </div>
          <div style={{
            marginTop: '15px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderRadius: '5px',
            paddingTop: '15px',
            paddingBottom: '15px'
          }}>
            <h6
              className="text-center"
              id="nameSuin"
            >
              Suin Juriscol
            </h6>
            <br />
            <div style={{ textAlign: 'center' }}>
              <a className="btn btn-secondary" href="https://www.suin-juriscol.gov.co/" role="button" target="_black">
                Ir a página
              </a>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => handleModal10(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Directorio de agremiaciones . . . */}

      <Modal
        isOpen={modal5}
        toggle={() => handleModal5(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => handleModal5(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Directorio de agremiaciones, asociaciones y
            otros grupos de interés
          </h5>
        </div>
        <div className="modal-body">
          <ul>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    fontFamily: "'Noto Sans SC', sans-serif"
                  }}
                  href="https://www.ucnc.com.co/sitio/"
                  target="_blank"
                >
                  <u>https://www.ucnc.com.co/sitio/</u>
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => handleModal5(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Preguntas y Respuestas Frecuentes */}

      <Modal
        isOpen={modal2}
        toggle={() => setModal2(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal2(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Preguntas y respuestas frecuentes
          </h5>
        </div>
        <div className="modal-body">
          <ul>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    fontFamily: "'Noto Sans SC', sans-serif"
                  }}
                  href="https://www.supernotariado.gov.co/portal/66.Ley%20de%20Trasparencia-66.2.Informacion%20de%20Interes/3.Boceto%20Manual%20Preguntas%20Frecuentes%20Servicio%20Notarial%20B.pdf"
                  target="_blank"
                >
                  <u>Superintendecia notariado y registro</u>
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    fontFamily: "'Noto Sans SC', sans-serif"
                  }}
                  href="https://www.registraduria.gov.co/-Preguntas-Frecuentes-219-"
                  target="_blank"
                >
                  <u>Registraduria Nacional del Estado Civil:</u>
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => setModal2(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Glosario */}

      <Modal
        isOpen={modal3}
        toggle={() => setModal3(false)}
      >
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal3(false)}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500, fontFamily: "'Noto Sans SC', sans-serif" }}
          >
            Glosario
          </h5>
        </div>
        <div className="modal-body">
          <ul>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    fontFamily: "'Noto Sans SC', sans-serif"
                  }}
                  href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/06/diligencias.pdf"
                  target="_blank"
                >
                  <u>Se remite a la página 46 del documento
                    "Cartilla de Diligencias Notariales" que
                    contiene el glosario de los términos más
                    usados en derecho notarial</u>
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => setModal3(false)}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SectionTransparencia;
