import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components


function LoginPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("full-screen");
    };
  });
 
  const [checkedEmail, setCheckedEmail] = React.useState(false);

  const check = e => {
    let m = document.getElementById("form");
    let x = document.getElementById("check").checked;
    setCheckedEmail(x);
    m.reset();
    let p = document.getElementById("mostrar");
    console.log(x);
    p.style.display = "block";
    setTimeout(() => {
      p.style.display = "none";
      setCheckedEmail(false);
    }, 2000);
  };


  return (
    <>
      {/* <ColorNavbar /> */}
      <div className="wrapper">
        <div
          className="page-header "
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/bga1.jpg") + ")"
          }}
        >
          <div className="filter" />
          <Container>
            <Card style={{ paddingBottom: "500px"}}>
              <CardBody style={{ padding: "54px", paddingTop:"80px"}}>
                <h6 className="author pull-left">Registro PQRS</h6>
                <span className="category-social text-info pull-right">
                <i className="fa fa-folder-open-o" aria-hidden="true"></i>
                </span>
                <div className="clearfix" />
                <p className="card-description my-4" style={{fontWeight:500}}>
                  Solicitud de información pública con identidad reservada aquí
                </p>
                <Form id="form">
                  <Row>
                    <Col className="col-md-4">
                      <div className="form-group">
                        <label for="inputState" style={{fontWeight:500}}>Tipo de Solicitante</label>
                        <select id="inputState" className="form-control">
                          <option selected>Seleccionar...</option>
                          <option>Persona Natural</option>
                          <option>Persona Juridica</option>
                          <option>Niñas, Niños y Adolecentes</option>
                          <option>Apoderado</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="col-md-4">
                      <div className="form-group">
                        <label for="inputState" style={{fontWeight:500}}>Tipo de Identificación</label>
                        <select id="inputState" className="form-control" required>
                          <option selected>
                            Seleccione un tipo de documento
                          </option>
                          <option>Cedula de Extranjeria</option>
                          <option>Pasaporte</option>
                          <option>Registro Civil</option>
                          <option>Tarjeta de Identidad</option>
                          <option>Otro</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="col-md-4">
                      <div clasNames="form-group">
                        <label style={{fontWeight:500}}>Número de Identificación</label>
                        <Input
                          id="id"
                          className=""
                          placeholder="Numero de Identificación"
                          type="number"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Primer Nombre</label>
                        <Input
                          id="pNombre"
                          className=""
                          placeholder="Primer Nombre"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Segundo Nombre</label>
                        <Input
                          id="sNombre"
                          className=""
                          placeholder="Segundo Nombre"
                          type="text"
                        />
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Primer Apellido</label>
                        <Input
                          id="pApellido"
                          className=""
                          placeholder="Primer Apellido"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Segundo Apellido</label>
                        <Input
                          id="sApellido"
                          className=""
                          placeholder="Segundo Apellido"
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Email</label>
                        <Input
                          id="email"
                          className=""
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Telefono fijo</label>
                        <Input
                          id="fijo"
                          className=""
                          placeholder="Telefono fijo"
                          type="number"
                        />
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Telefono Movil</label>
                        <Input
                          id="movil"
                          className=""
                          placeholder="Telefono Movil"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <div className="form-group">
                        <label style={{fontWeight:500}}>Dirección</label>
                        <Input
                          id="direccion"
                          className=""
                          placeholder="Dirección"
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="ml-auto mr-auto">
                    <Col className="col-md-8 ">
                      <div style={{ fontWeight: 600 }} role="alert">
                        La respuesta a su solicitud le será enviada a través de
                        la dirección de correspondencia o al correo electrónico,
                        por lo tanto verifique que los datos se incluyeron
                        correctamente.
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="col-md-4">
                      <div className="form-group">
                        <label for="inputState" style={{fontWeight:500}}>Tipo de Solicitud</label>
                        <select id="inputState" className="form-control">
                          <option selected>
                            Seleccionar el tipo de solicitud
                          </option>
                          <option>Cuenta de Cobro</option>
                          <option>Informes</option>
                          <option>Petición Dec 491 2020</option>
                          <option>Recursos de reposición</option>
                          <option>Registro Ser</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="col-md-4">
                      <div className="form-group">
                        <label style={{fontWeight:500}} for="inputState">Medio de Respuesta</label>
                        <select id="inputState" className="form-control" required>
                          <option selected>
                            Seleccione medio de respuesta
                          </option>
                          <option>Correo Electronico</option>
                          <option>Dirección residencial</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="col-md-4">
                      <div className="form-group">
                        <label style={{fontWeight:500}} for="details">
                         Detalles
                        </label>
                        <textarea
                          className="form-control"
                          id="details"
                          rows="3"
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  
                  <Row className="mt-4">
                    <Col className="col-md-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radio1"
                          id="radio1"
                          value="option1"
                          checked
                        ></input>
                        <label className="form-check-label" for="radio1" style={{fontWeight:500}}>
                          He leído y estoy de acuerdo con los términos y
                          condiciones de uso de datos, implementados por el
                          Ministerio de Tecnologías de la Información y las
                          Comunicaciones
                        </label>
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radio2"
                          id="radio2"
                          value="option2"
                          checked
                        ></input>
                        <label className="form-check-label" for="radio2" style={{fontWeight:500}}>
                          Certifico que los datos de contacto ingresados en mis
                          datos personales se encuentra vigentes, de igual
                          manera autorizo al Ministerio de Tecnologías de la
                          Información y las Comunicaciones para el envío de la
                          respuesta a mi solicitud por este medio.
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4 ml-auto mr-auto">
                    <Col className="col-md-3 ">
                      <div className="form-check">
                        <input
                          id="check"
                          className="form-check-input"
                          type="radio"
                        ></input>
                        <label className="form-check-label" for="radio2" style={{fontWeight:500}}>
                          No soy un robot
                        </label>
                      </div>
                    </Col>
                    <Col className="col-md-3" id="mostrar">
                      {checkedEmail && (
                        <div
                          className="alert alert-danger font-weight-normal my-1"
                          role="alert"
                        >
                          Enviado!
                        </div>
                      )}
                    </Col>        
                    <Col className="col-md-6 text-right">
                      <Button className="btn-rotate btn-round mr-1 " type="button" color="default" onClick={check}>
                        <i className="nc-icon nc-settings-gear-65 mr-1" />
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
