import React from "react";

// reactstrap components
import {
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container
} from "reactstrap";

// core components
function BlogPost() {
  const [hTabs, setHTabs] = React.useState("1");
  const [vTabs, setVTabs] = React.useState("1");

  return (
    <>
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container className="tim-container">
              <div id="description-areas">
                <Row style={{ paddingTop: "90px" }}>
                  <Col
                    className="ml-auto mr-auto text-center title "
                    style={{ paddingBottom: "48px" }}
                    md="6"
                  >
                    <h2>Formalidad, Procedimiento y Papeleo</h2>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto" md="20">
                    <div className="nav-tabs-navigation text-center ">
                      <div
                        className="nav-tabs-wrapper text-center pl-4"
                        style={{ backgroundColor: "#277b06" }}
                      >
                        <Nav id="tabs" role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              className={hTabs === "1" ? "active" : ""}
                              onClick={() => {
                                setHTabs("1");
                              }}
                            >
                              <div className="">
                                <div className="icon ">
                                  <i
                                    className="fa fa-address-card-o fa-2x pr-5 pl-3 pb-1 pt-2"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <p
                                  className="font-weight-bolder pt-2"
                                  style={{ fontSize: "13px" }}
                                >
                                  REGISTRO CIVIL
                                </p>
                              </div>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={hTabs === "2" ? "active" : ""}
                              onClick={() => {
                                setHTabs("2");
                              }}
                            >
                              <div className="">
                                <div className="icon ">
                                  <i
                                    className="fa fa-handshake-o fa-2x pr-5 pl-3 pb-1 pt-2"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <p
                                  className="font-weight-bolder pt-2"
                                  style={{ fontSize: "13px" }}
                                >
                                  MATRIMONIO
                                </p>
                              </div>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={hTabs === "3" ? "active" : ""}
                              onClick={() => {
                                setHTabs("3");
                              }}
                            >
                              <div className="">
                                <div className="icon ">
                                  <i
                                    className="fa fa-pencil-square-o fa-2x pr-5 pl-3 pb-1 pt-2"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <p
                                  className="font-weight-bolder pt-2"
                                  style={{ fontSize: "13px" }}
                                >
                                  ESCRITURACIÓN
                                </p>
                              </div>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={hTabs === "4" ? "active" : ""}
                              onClick={() => {
                                setHTabs("4");
                              }}
                            >
                              <div className="">
                                <div className="icon ">
                                  <i
                                    className="fa fa-check-square-o fa-2x pr-5 pl-3 pb-1 pt-2"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <p
                                  className="font-weight-bolder pt-2"
                                  style={{ fontSize: "13px" }}
                                >
                                  AUTENTICACIONES
                                </p>
                              </div>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    <div className="nav-tabs-navigation text-center ">
                      <div
                        className="nav-tabs-wrapper text-center pl-4"
                        style={{ backgroundColor: "#277b06" }}
                      >
                        <Nav id="tabs" role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              className={hTabs === "5" ? "active" : ""}
                              onClick={() => {
                                setHTabs("5");
                              }}
                            >
                              <div className="">
                                <div className="icon ">
                                  <i
                                    className="fa fa-file-text-o fa-2x pr-5 pl-3 pb-1 pt-2"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <p
                                  className="font-weight-bolder pt-2"
                                  style={{ fontSize: "13px" }}
                                >
                                  DECLARACIONES
                                </p>
                              </div>
                            </NavLink>
                          </NavItem>
                          <NavItem className="pl-md-3">
                            <NavLink
                              className={hTabs === "6" ? "active" : ""}
                              onClick={() => {
                                setHTabs("6");
                              }}
                            >
                              <div className="">
                                <div className="icon ">
                                  <i
                                    className="fa fa-gavel fa-2x pr-5 pl-3 pb-1 pt-2"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <p
                                  className="font-weight-bolder pt-2"
                                  style={{ fontSize: "13px" }}
                                >
                                  SUCESIONES
                                </p>
                              </div>
                            </NavLink>
                          </NavItem>
                          <NavItem className="">
                            <NavLink
                              className={hTabs === "7" ? "active" : ""}
                              onClick={() => {
                                setHTabs("7");
                              }}
                            >
                              <div className="">
                                <div className="icon ">
                                  <i
                                    className="fa fa-plus fa-2x pr-5 pl-3 pb-1 pt-2"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <p
                                  className="font-weight-bolder pt-2"
                                  style={{ fontSize: "13px" }}
                                >
                                  OTROS
                                </p>
                              </div>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    <TabContent
                      className="text-center"
                      activeTab={"hTabs" + hTabs}
                    >
                      <TabPane tabId="hTabs1">
                        <Col md="12">
                          <Row>
                            <Col md="3">
                              <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                  <Nav
                                    className="flex-column nav-stacked mt-4 text-left"
                                    role="tablist"
                                    tabs
                                  >
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "1"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("1");
                                        }}
                                      >
                                        <span className="float-left">
                                          {" "}
                                          <i
                                            className="fa fa-paperclip  mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>{" "}
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Registro Civil de Nacimiento
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "2"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("2");
                                        }}
                                      >
                                        <span className="float-left">
                                          {" "}
                                          <i
                                            className="fa fa-paperclip  mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>{" "}
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Registro Civil de Matrimonio{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "3"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("3");
                                        }}
                                      >
                                        <span className="float-left">
                                          {" "}
                                          <i
                                            className="fa fa-paperclip  mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>{" "}
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Registro Civil de Defunción{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "4"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("4");
                                        }}
                                      >
                                        <span className="float-left">
                                          {" "}
                                          <i
                                            className="fa fa-paperclip  mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>{" "}
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Cambio de Nombre{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              {/* Tab panes */}
                              <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Registro Civil de Nacimiento
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es un documento indispensable mediante el
                                      cual la persona prueba ante la familia y
                                      la sociedad su existencia y le permite
                                      identificarse, ejercer sus derechos y
                                      cumplir sus obligaciones
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Certificado de nacido vivo dado por el
                                          médico, centro hospitalario, enfermera
                                          o persona que haya ayudado a la madre
                                          en el parto. Si se trata de un menor
                                          que pertenece a una comunidad
                                          indígena, puede servir como documento
                                          la autorización indigena.
                                        </li>
                                        <li className="pb-2">
                                          En caso de no contar con el anterior
                                          certificado, se requiere declaración
                                          bajo juramento de dos testigos que
                                          hayan presenciado el nacimiento o
                                          hayan tenido conocimiento de él.
                                        </li>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          padres o de las personas que estén
                                          autorizadas por la ley para realizar
                                          el registro, como abuelos, tíos o
                                          parientes mayores.
                                        </li>
                                        <li className="pb-2">
                                          El registro que se hace después del
                                          mes de nacido es extemporáneo y las
                                          personas que no cuenten con ninguno de
                                          los anteriores requisitos, pueden
                                          presentar la partida de bautismo
                                          católico o la anotación de origen
                                          religioso, si es de otro credo
                                          reconocido por el Estado.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs2">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Registro civil de Matrimonio
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es el documento público necesario para
                                      probar que una persona está casada. En él
                                      se registran los datos de quienes se casan
                                      y el acto del matrimonio. Sirve para
                                      adquirir derechos y cumplir obligaciones
                                      entre sí, ante la familia, las autoridades
                                      y la sociedad.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Copia auténtica del registro civil de
                                          nacimiento de quienes se casan.
                                        </li>
                                        <li className="pb-2">
                                          Documento de identificación de quienes
                                          se casan.
                                        </li>
                                        <li className="pb-2">
                                          Si el matrimonio fue católico. copia
                                          auténtica de la partida parroquial.
                                        </li>
                                        <li className="pb-2">
                                          Si el matrimonio fue de otra religión,
                                          copia auténtica dada por la confesión
                                          religiosa debidamente reconocida por
                                          el Estado.
                                        </li>
                                        <li className="pb-2">
                                          Si el matrimonio se realizó ante juez,
                                          acta del matrimonio debidamente
                                          protocolizada en notaría.
                                        </li>
                                        <li className="pb-2">
                                          Si el matrimonio fue celebrado en el
                                          exterior, hay requisitos adicionales
                                          que deben consultarse con el notario.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> La inscripción en
                                      el registro no tiene costo. Las copias,
                                      según tarifa notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs3">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Registro Civil de Defunción
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es el documento público que prueba el
                                      hecho de la muerte y su causa. Sirve para
                                      que la defunción quede anotada en el
                                      registro civil de nacimiento y en el
                                      registro civil de matrimonio, si lo hubo.
                                      También permite el inicio de los trámites
                                      de sucesión. Además, la diligencia de
                                      defunción la comunica el notario a la
                                      Registraduría Nacional del Estado Civil
                                      para cancelar la cédula de ciudadanía.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <div>
                                        <li className="pb-2">
                                          Denuncio de la defunción dentro de los
                                          dos días después de haber conocido el
                                          fallecimiento. De haber excedido este
                                          plazo, debe presentar orden impartida
                                          por el inspector de policía, previa
                                          solicitud escrita del interesado donde
                                          explica las causas del retraso.
                                        </li>
                                        <li className="pb-2">
                                          Certificado médico de defunción o
                                          declaración de dos testigos cuando no
                                          haya médico en la localidad.
                                        </li>
                                        <li className="pb-2">
                                          Copia de la cédula de ciudadanía de
                                          quien registra el fallecimiento.
                                        </li>
                                        <li className="pb-2">
                                          Orden judicial, si la muerte fue
                                          violenta o se desconocen los motivos.
                                          Para el caso de muerte presunta por
                                          desaparecimiento, sentencia judicial
                                          en firme.
                                        </li>
                                      </div>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong>La inscripción en
                                      el registro no tiene costo. Las copias,
                                      según tarifa notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs4">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Cambio de Nombre
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      El cambio de nombre lo podrá hacer la
                                      persona mayor de edad voluntariamente o
                                      los padres en representación del menor de
                                      edad, para suprimir, adicionar o modificar
                                      sus nombres o sus apellidos y por regla
                                      general, solo puede hacerse una vez por
                                      escritura pública ante notario. Los nuevos
                                      cambios deben estar orientados a
                                      garantizar la identidad entre el nombre y
                                      el género o evitar discriminaciones.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        "ARTICULO 6°. El Articulo 94 del
                                        Decreto-Ley 1260 de 1970", quedará así:
                                      </strong>
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      ARTICULO 94. «Aparte subrayado
                                      CONDICIONALMENTE exequible* El propio
                                      inscrito podrá disponer, po una sola vez,
                                      mediante escritura pública la modificación
                                      de registro, para sustituir, rectificar,
                                      corregir o adicionar Su nombre, todo con
                                      el fin de fijar su identidad personal.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Corte Constitucional</strong>
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      La expresión subrayada 'por primera vez'
                                      declarada CONDICIONALMENTE EXEQUIBLE por
                                      la Corte Constitucional mediante Sentencia
                                      C-114-17 de 22 de febrero de 2017
                                      Magistrado Ponente Dr Alejandro Linares
                                      Cantillo. •en et entendido de que tal
                                      restricción no será aplica ble en aquellos
                                      eventos en que exista una justificación
                                      constitucional, clara y suficiente, de
                                      conformidad con los criterios establecidos
                                      en la parte motiva de esta sentencia.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Debe ser realizado directamente por la
                                          persona mayor de edad que quiere
                                          cambiarse el nombre y si el cambio es
                                          para un menor de edad, deben
                                          realizarlo sus padres o quien tenga la
                                          patria potestad.
                                        </li>
                                        <li className="pb-2">
                                          Presentar documento de identificación
                                          y copia auténtica del registro civil
                                          de nacimiento que se va a modificar.
                                        </li>
                                        <li className="pb-2">
                                          Si se trata de integrantes de
                                          comunidades o pueblos indígenas, deben
                                          presentar copia del registro civil de
                                          nacimiento y el permiso de la
                                          autoridad indigena reconocida por el
                                          Estado
                                        </li>
                                        <li className="pb-2">
                                          La mujer casada podrá proceder, por
                                          medio de escritura pública, a
                                          adicionar o suprimir el apellido del
                                          marido precedido de la preposición
                                          "de", en los casos en que ella lo
                                          hubiere adoptado o hubiere sido
                                          establecido por la ley.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      El instrumento a que se refiere el
                                      presente artículo deberá inscribirse en el
                                      correspondiente registro civil del
                                      interesado, para lo cual se procederá a la
                                      apertura de un nuevo folio. El original y
                                      el sustituto llevarán notas de recíproca
                                      referencia.
                                    </p>
                                    <p className="text-justify font-weight-normal pt-2">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Si. Según tarifa
                                      notarial. Para miembros de comunidades
                                      indígenas, esta diligencia, incluidas las
                                      copias auténticas, no tienen Costo.
                                    </p>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="hTabs2">
                        <Col md="12">
                          <Row>
                            <Col md="3">
                              <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                  <Nav
                                    className="flex-column nav-stacked text-left mt-4"
                                    role="tablist"
                                    tabs
                                  >
                                    <NavItem style={{ marginBottom: "10px" }}>
                                      <NavLink
                                        className={
                                          vTabs === "1"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("1");
                                        }}
                                      >
                                        <i
                                          className=" fa fa-bell-o mr-3"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                        <span
                                          className=" font-weight-normal"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          Matrimonio{" "}
                                        </span>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "2"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("2");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-bell-o mr-3"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Capitulaciones Matrimoniales/{" "}
                                          </span>{" "}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: "-7px",
                                            marginLeft: "33px"
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Maritales{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "3"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("3");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-bell-o mr-3"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Cesación de los efectos civiles{" "}
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "34px",
                                            marginTop: "-5px"
                                          }}
                                        >
                                          {" "}
                                          de la unión marital de hecho
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "4"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("4");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-bell-o mr-3"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Disolución y Liquidación de{" "}
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "34px",
                                            marginTop: "-5px"
                                          }}
                                        >
                                          la sociedad patrimonial
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "5"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("5");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-bell-o mr-3"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Declaración de{" "}
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "34px",
                                            marginTop: "-5px"
                                          }}
                                        >
                                          Unión Marital
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "6"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("6");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-bell-o mr-3"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Declaración de existencia de
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "34px",
                                            marginTop: "-5px"
                                          }}
                                        >
                                          {" "}
                                          la sociedad patrimonial
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "7"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("7");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-bell-o mr-3"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Divorcio
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "8"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("8");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-bell-o mr-3"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Liquidación de Sociedad Conyugal
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              {/* Tab panes */}
                              <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Matrimonio
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es un contrato solemne celebrado ante juez
                                      o notario, por el cual se unen legalmente
                                      un hombre y una mujer o dos personas del
                                      mismo sexo, con el fin de vivir juntos,
                                      auxiliarse mutuamente y constituir una
                                      familia. Por el hecho del matrimonio, nace
                                      la sociedad conyugal o sociedad de los
                                      bienes adquiridos dentro del matrimonio.
                                      La diligencia de matrimonio termina con la
                                      firma del notario denominada autorización
                                      notarial en la escritura pública.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Solicitud escrita ante el notario por
                                          ambos interesados o por intermedio de
                                          sus apoderados, con nombres,
                                          apellidos, documentos de identidad,
                                          lugar de nacimiento, edad, ocupación y
                                          domicilio de los contrayentes y nombre
                                          de sus padres, indicando que no tienen
                                          impedimento legal para celebrar el
                                          matrimonio.
                                        </li>
                                        <li className="pb-2">
                                          Copias auténticas de los registros
                                          civiles de nacimiento de los
                                          contrayentes, que no tengan más de un
                                          mes de expedidas. Se requiere la copia
                                          completa que incluya todas las
                                          anotaciones de estados civiles
                                          anteriores para establecer que no hay
                                          impedimentos para el nuevo matrimonio.
                                          Si alguno de los contrayentes es
                                          extranjero, el registro civil no debe
                                          tener más de tres meses de expedido.
                                        </li>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          contrayentes.
                                        </li>
                                        <li className="pb-2">
                                          Los menores de edad deben entregar
                                          autorización escrita de sus padres.
                                        </li>
                                        <li className="pb-2">
                                          Si tienen hijos y los contrayentes son
                                          el padre y la madre (hijos comunes)
                                          deben anexar las copias de los
                                          registros civiles de nacimiento de
                                          cada uno de los hijos.
                                        </li>
                                        <li className="pb-2">
                                          Para el caso de hijos no comunes que
                                          se encuentren bajo su patria potestad
                                          o bajo su guarda, deben presentar
                                          inventario de los bienes, si los hay,
                                          que sean de propiedad de cada hijo.
                                        </li>
                                        <li className="pb-2">
                                          El inventario lo elaborará el curador
                                          designado según la ley. Sobre este
                                          trámite, consultar al notario.
                                        </li>
                                        <li className="pb-2">
                                          Quienes se casaron por matrimonio
                                          religioso, deben llevar la partida
                                          eclesiástica del matrimonio católico 0
                                          el certificado de la religión
                                          reconocida por el Estado, para que se
                                          haga el registro civil en notaría o en
                                          la Registraduría.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Consulte con el notario, dado que la
                                      celebración del matrimonio depende del
                                      cumplimiento de los requisitos legales.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>

                                <TabPane tabId="vTabs2">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Capitulaciones matrimoniales / Maritales
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">
                                          Copia de las cedulas de los
                                          contrayentes
                                        </li>
                                        <li className="pb-2">
                                          Identificación de los bienes a
                                          inventariar así:
                                          <ul>
                                            <li className="pb-2">
                                              Descripción del inmueble
                                            </li>
                                            <li className="pb-2">
                                              Numero de matrícula inmobiliaria{" "}
                                            </li>
                                            <li className="pb-2">Avaluó </li>
                                          </ul>
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>

                                <TabPane tabId="vTabs3">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Cesación de los efectos civiles de la
                                        unión marital de hecho
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Sin hijos</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">
                                          Acuerdo suscrito por los compañeros
                                          permanentes
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública. de la declaración
                                          de unión marital de hecho
                                        </li>
                                        <li className="pb-2">
                                          Registro civil de nacimiento de los
                                          compañeros permanentes
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Con hijos</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">
                                          Registro civil del menor
                                        </li>
                                        <li className="pb-2">
                                          Acta de conciliación comisaria de
                                          familia o acuerdo de alimentos
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública. de la declaración
                                          de unión marital de hecho
                                        </li>
                                        <li className="pb-2">
                                          Registro civil de nacimiento de los
                                          compañeros permanentes
                                        </li>
                                        <li className="pb-2">
                                          Registro civil de nacimiento de los
                                          menores
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs4">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Disolución y Liquidación de la Sociedad
                                        Patrimonial
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Sin bienes:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">Solicitud</li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Con bienes</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">
                                          De los Inmuebles
                                          <ul>
                                            <li>
                                              Certificado de libertad y
                                              tradición{" "}
                                            </li>
                                            <li>
                                              Copia de la escritura pública de
                                              adquisición{" "}
                                            </li>
                                            <li>
                                              Paz y salvos predial,
                                              valorización, área metropolitana
                                              (según corresponda)
                                            </li>
                                          </ul>
                                        </li>
                                        <li className="pb-2">
                                          De los muebles:
                                          <ul>
                                            <li>
                                              Documento que lo acredite, según
                                              corresponda
                                            </li>
                                          </ul>
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs5">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Declaración de Unión Marital
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">
                                          Registros civiles de nacimiento de la
                                          compañera permanente
                                        </li>
                                        <li className="pb-2">
                                          Registro civil de nacimiento del
                                          compañero permanente
                                        </li>
                                        <li className="pb-2">
                                          <b>Observaciones:</b> fecha en que
                                          inicio la convivencia
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs6">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Declaración de existencia de sociedad
                                        patrimonial
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">
                                          Copia de las cedulas de ciudadanía de
                                          los compañeros permanentes
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs7">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Divorcio
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal">
                                      <strong>Sin hijos</strong>{" "}
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Registro de matrimonio
                                        </li>
                                        <li className="pb-2">
                                          Registro de nacimiento de los esposos
                                          *2
                                        </li>
                                        <li className="pb-2">
                                          Acuerdo conyugal
                                        </li>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">Solicitud</li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Con hijos menores de edad</strong>{" "}
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Registro de matrimonio
                                        </li>
                                        <li className="pb-2">
                                          Registro de nacimiento de los esposos
                                        </li>
                                        <li className="pb-2">
                                          Registro de nacimiento de los hijos
                                        </li>
                                        <li className="pb-2">
                                          Acuerdo conyugal
                                        </li>
                                        <li className="pb-2">
                                          Acuerdo de menores
                                        </li>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">Solicitud</li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs8">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Liquidación de Sociedad Conyugal
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal">
                                      <strong>Sin bienes</strong>{" "}
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Registro de matrimonio
                                        </li>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">
                                          Copia de las cedulas de ciudadanía
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Con bienes:</strong>{" "}
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Registro civil de matrimonio
                                        </li>
                                        <li className="pb-2">Poder</li>
                                        <li className="pb-2">Minuta</li>
                                        <li className="pb-2">
                                          Copia de las cedulas de ciudadanía
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvos según corresponda
                                        </li>
                                        <li>
                                          De los muebles:
                                          <ul>
                                            <li>
                                              Vehículos: tarjeta de propiedad
                                            </li>
                                            <li>
                                              Cuentas bancarias: certificado del
                                              banco{" "}
                                            </li>
                                          </ul>
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="hTabs3">
                        <Col md="12">
                          <Row>
                            <Col md="3">
                              <div className="nav-tabs-navigation">
                                <div
                                  style={{ height: "400px" }}
                                  className="nav-tabs-wrapper overflow-auto"
                                >
                                  <Nav
                                    className="flex-column nav-stacked text-left mt-4 "
                                    role="tablist"
                                    tabs
                                  >
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "1"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("1");
                                        }}
                                      >
                                        <i
                                          className=" fa fa-pencil mr-3"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                        <span
                                          className=" font-weight-normal"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          Escrituración{" "}
                                        </span>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "3"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("3");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Compraventa de Inmuebles{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "4"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("4");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Permuta de Inmuebles{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "5"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("5");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Constitución del reglamento{" "}
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "34px",
                                            marginTop: "-5px"
                                          }}
                                        >
                                          Propiedad Horizontal
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "6"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("6");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Servidumbre
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem style={{ marginTop: "-25px" }}>
                                      <NavLink
                                        className={
                                          vTabs === "7"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("7");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Reforma Propiedad Horizontal
                                          </span>{" "}
                                        </p>

                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "8"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("8");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Extinción de la propiedad
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "34px",
                                            marginTop: "-5px"
                                          }}
                                        >
                                          Horizontal
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "9"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("9");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Actualización de área y linderos
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "10"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("10");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Subdivisión
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "11"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("11");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Englobe
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "12"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("12");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Declaración de Construcción
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "13"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("13");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Constitución patrimonio
                                          </span>{" "}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "35px"
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            familia
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "14"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("14");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Autorización para venta de menores
                                          </span>{" "}
                                        </p>

                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "15"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("15");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Venta de derechos
                                          </span>{" "}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "35px"
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            herenciales universales
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "16"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("16");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Venta de derechos
                                          </span>{" "}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "35px"
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            herenciales vinculados
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "17"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("17");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Venta de derechos
                                          </span>{" "}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "35px"
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            gananciles
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "19"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("19");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Cancelación de patrimonio
                                          </span>{" "}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "35px"
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            con menores de edad
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "20"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("20");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Inventario de bienes de
                                          </span>{" "}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "35px"
                                          }}
                                        >
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            menor de edad
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "21"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("21");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-pencil mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Contrato de permuta
                                          </span>{" "}
                                        </p>

                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              {/* Tab panes */}
                              <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Escrituración
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es un documento que contiene la
                                      declaración de voluntad de una o varias
                                      personas, emitidas ante el notario, para
                                      ahcer un determinado contrato o un acto
                                      jurídico individual.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <p className="text-justify font-weight-normal ">
                                      El proceso de elaboración de una escritura
                                      pública tiene cuatro etapas en la notaría:
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Recepción de las declaraciones, esot
                                          es, de lo que el usuario quiere decir
                                          ante el notario.
                                        </li>
                                        <li className="pb-2">
                                          Extensión o transcripció en texto
                                          escrito de lo dicho o declarado por el
                                          usuario, ante el notario.
                                        </li>
                                        <li className="pb-2">
                                          Lectura, aceptación y otorgamiento del
                                          texto que ha leído o ha oido el
                                          usuario.
                                        </li>
                                        <li className="pb-2">
                                          Autorización y firma del notario,
                                          después de la firma de los interesados
                                          y después de cumplidos todos los
                                          requisitos del contrato respectivo.
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>

                                <TabPane tabId="vTabs3">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Compraventa de un Inmueble
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Mediante este contrato una persona se
                                      convierte en propietaria de una casa, lote
                                      sin construcción, apartamento o una finca,
                                      entre otros. Consiste en traspasar a Otra
                                      persona uno de esos inmuebles a cambio de
                                      un valor en dinero que el comprador le
                                      paga al vendedor. Este contrato se hace
                                      obligatoriamente por medio de escritura
                                      pública, Vendedor y comprador deben
                                      cumplir dos etapas:
                                    </p>
                                    <p className="text-justify font-weight-normal py-1">
                                      <strong>
                                        Primera etapa en la notaría:
                                      </strong>{" "}
                                      Hacer la escritura pública de compraventa,
                                      en la que se especifiquen los linderos de
                                      la vivienda o de la finca y el precio.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          interesados.
                                        </li>
                                        <li className="pb-2">
                                          El vendedor debe ser el dueño del
                                          inmueble y mayor de edad. El comprador
                                          también debe ser mayor de edad
                                        </li>
                                        <li className="pb-2">
                                          Si una de las partes es persona menor
                                          de edad, deberá comprar o vender por
                                          representante legal (padres en
                                          ejercicio de la patria potestad, entre
                                          otros).
                                        </li>
                                        <li className="pb-2">
                                          Certificados de paz y salvo de
                                          impuesto predial que contenga el
                                          avalúo catastral y de contribución de
                                          valorización, si aplica en el
                                          municipio. Si el inmueble está
                                          sometido a propiedad horizontal, se
                                          necesita también el certificado de paz
                                          y salvo por el pago de la
                                          administración.
                                        </li>
                                        <li className="pb-2">
                                          Si el vendedor o comprador es una
                                          persona jurídica, como una sociedad o
                                          un banco, debe presentarse el
                                          documento que acredite su existencia y
                                          representación legal.
                                        </li>
                                        <li className="pb-2">
                                          Se recomienda que el vendedor, como
                                          dueño de la vivienda, presente al
                                          notario copia de la escritura pública
                                          o título de adquisición, junto con el
                                          certificado de libertad y tradición
                                          más reciente que le da la Oficina de
                                          Registro de Instrumentos Públicos,
                                          para poderverificar la historia del
                                          inmueble y los linderos.
                                        </li>
                                        <li className="pb-2">
                                          Cuando el apartamento esté en el
                                          régimen de propiedad horizontal, se
                                          debe presentar copia de la parte del
                                          reglamento que se refiere a la
                                          propiedad que se vende.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-1">
                                      <strong>
                                        Segunda etapa en la oficina de registro
                                        de instrumentos publicos:{" "}
                                      </strong>{" "}
                                      Una vez terminada la primera etapa, o sea,
                                      la de la escritura pública, el notario
                                      entrega copia auténtica a los interesados
                                      para llevarla a esta oficina.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Es de mínimo ocho días hábiles, si la
                                      escritura es firmada en el mismo momento
                                      por vendedor y comprador, para que el
                                      notario revise la legalidad del contrato.
                                      Si no firman al tiempo, puede superar este
                                      plazo. Consulte al notario.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs4">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Permuta de Inmuebles
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es uno de los contratos para que una
                                      persona se convierta en propietaria de una
                                      vivienda, lote sin construcción o de una
                                      finca, entre otros bienes. Consiste, por
                                      ejemplo, en el intercambio de una vivienda
                                      0 de una finca por casas o cosas y dinero.
                                      Para que sea una permuta, el intercambio
                                      debe hacerse por un valor mayor en cosas
                                      que en dinero. Si el valores mayor en
                                      dinero, el contrato será de compraventa.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      Para realizar este contrato que se hace
                                      por medio de escritura pública, los
                                      interesados deben cumplir dos etapas:
                                    </p>
                                    <p className="text-justify font-weight-normal py-1">
                                      <strong>
                                        Primera etapa en la notaría:
                                      </strong>{" "}
                                      Hacer la escritura pública de permuta, en
                                      la que se especifiquen los linderos de los
                                      bienes que se intercambian y el precio de
                                      los mismos.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Documentos de identificación de las
                                          personas que hacen el contrato.
                                        </li>
                                        <li className="pb-2">
                                          Estas personas deben ser mayores de
                                          edad y por lo tanto están en capacidad
                                          de hacerlo.
                                        </li>
                                        <li className="pb-2">
                                          Certificados de paz y salvo por
                                          impuesto predial que se paga en la
                                          tesorería municipal y que contiene el
                                          avalúo catastral. Además, el paz y
                                          salvo por contribución de
                                          valorización, si aplica en el
                                          municipio. Si el inmueble está
                                          sometido a propiedad horizontal, se
                                          necesita también el certificado de paz
                                          y salvo por el pago de cuotas de la
                                          administración.
                                        </li>
                                        <li className="pb-2">
                                          Si alguno de quienes hacen el
                                          contrato, es persona jurídica, como
                                          una sociedad o un banco, debe
                                          presentarse el documento que acredite
                                          su existencia y representación legal.
                                        </li>
                                        <li className="pb-2">
                                          Se recomienda que se lleve al notario
                                          copia de la escritura pública o título
                                          de adquisición, junto con el
                                          certificado de libertad y tradición
                                          más reciente que le ha dado la Oficina
                                          de Registro de Instrumentos Públicos.
                                        </li>
                                        <li className="pb-2">
                                          Se sugiere que se presente copia de la
                                          parte del reglamento que se refiere a
                                          las propiedades que se permutan.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-1">
                                      <strong>
                                        Segunda etapa en la oficina de registro
                                        de instrumentos publicos:{" "}
                                      </strong>{" "}
                                      Una vez terminada la primera etapa, o sea,
                                      la de la escritura pública, el notario
                                      entrega copia auténtica a los interesados
                                      para llevarla a esta oficina.
                                    </p>
                                    <p className="text-justify font-weight-normal py-1">
                                      Para ser propietario de bienes inmuebles
                                      se requiere la escritura pública
                                      debidamente registrada donde consten la
                                      compraventa o la permuta. No olvide este
                                      requisito.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Si firman al tiempo los interesados, es
                                      mínimo ocho días hábiles, mientras el
                                      notario revisa la legalidad del contrato.
                                      firman al tiempo, puede superareste plazo.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs5">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Constitución del Reglamento Propiedad
                                        Horizontal
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Licencia o resolución
                                        </li>
                                        <li className="pb-2">Visto bueno PH</li>
                                        <li className="pb-2">
                                          Boletín de nomenclatura
                                        </li>
                                        <li className="pb-2">
                                          Nota ejecutoria
                                        </li>
                                        <li className="pb-2">
                                          Certificación igac sin recurso.
                                        </li>
                                        <li className="pb-2">
                                          Planos aprobados vobo ph
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura copia simple
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvos del impuesto predial y
                                          valorización según corresponda
                                        </li>
                                        <li className="pb-2">
                                          Certificados técnicos de ocupación
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvo área metropolitana según
                                          corresponda
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs6">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Servidumbre
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Predio dominante / predio sirviente
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvos: predial, valorización y
                                          área metropolitana según corresponda
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvo de administración si se
                                          cancela
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvo de gobernación para los
                                          predios de ruitoque
                                        </li>
                                        <li className="pb-2">minuta </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs7">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Reforma Propiedad Horizontal
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Acta de la junta
                                        </li>
                                        <li className="pb-2">
                                          Personería jurídica
                                        </li>
                                        <li className="pb-2">Nit</li>
                                        <li className="pb-2">
                                          Certificado de existencia y
                                          representación del nombramiento del
                                          administrador
                                        </li>
                                        <li className="pb-2">Minuta</li>
                                        <li className="pb-2">
                                          Paz y salvo predial, valorización y
                                          área metropolitana según corresponda
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvo de administración (si
                                          cancelan)
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvo de gobernación para los
                                          predios de ruitoque
                                        </li>
                                        <li className="pb-2">
                                          Copia de las cedulas de ciudadanía
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs8">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Extinción de la Propiedad Horizontal
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Acta de la junta
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición de
                                          cada inmueble
                                        </li>
                                        <li className="pb-2">
                                          Escritura de constitución del
                                          reglamento de propiedad horizontal
                                        </li>
                                        <li className="pb-2">
                                          Escritura de adquisición de cada
                                          inmueble
                                        </li>
                                        <li className="pb-2">
                                          Personería jurídica
                                        </li>
                                        <li className="pb-2">Nit</li>
                                        <li className="pb-2">
                                          Certificado de existencia y
                                          representación del nombramiento del
                                          administrador
                                        </li>
                                        <li className="pb-2">Minuta </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs9">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Actualización de área y linderos
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Resolución</li>
                                        <li className="pb-2">
                                          Nota ejecutoria
                                        </li>
                                        <li className="pb-2">
                                          Certificado catastral especial
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">Minuta</li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs10">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Subdivisión
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición
                                        </li>
                                        <li className="pb-2">Licencia </li>
                                        <li className="pb-2">Resolución</li>
                                        <li className="pb-2">Ejecutoria</li>
                                        <li className="pb-2">
                                          Planos aprobados/ autenticados
                                        </li>
                                        <li className="pb-2">Minuta </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs11">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Englobe
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición
                                        </li>
                                        <li className="pb-2">
                                          Plano topográfico, con firma del
                                          topógrafo, linderos técnicos
                                          georreferenciados del nuevo lote
                                        </li>
                                        <li className="pb-2">Minuta</li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs12">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Declaración de construcción
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición
                                        </li>
                                        <li className="pb-2">
                                          Copia de las cedulas de ciudadanía
                                        </li>
                                        <li className="pb-2">
                                          Licencia de construcción
                                        </li>
                                        <li className="pb-2">resolución</li>
                                        <li className="pb-2">Resolución</li>
                                        <li className="pb-2">
                                          Nota ejecutoria{" "}
                                        </li>
                                        <li className="pb-2">
                                          Plano aprobado{" "}
                                        </li>
                                        <li className="pb-2">Minuta</li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs13">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Constitución patrimonio de familia
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">
                                          Registro civil Matrimonio
                                        </li>
                                        <li className="pb-2">
                                          Registro civil nacimiento de los
                                          menores
                                        </li>
                                        <li className="pb-2">Copia cedulas</li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs14">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Autorización para venta de menores
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Solicitud presentada bajo la gravedad
                                          de juramento
                                        </li>
                                        <li className="pb-2">
                                          Registro de nacimiento del menor de
                                          edad copia tomada del original
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Avaluó catastral
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs15">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Venta de derechos herenciales
                                        universales
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Poder si así lo requiere
                                        </li>
                                        <li className="pb-2">
                                          Certificado de defunción
                                        </li>
                                        <li className="pb-2">
                                          Registro de nacimiento del heredero
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs16">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Venta de derechos herenciales vinculados
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Poder si así lo requiere
                                        </li>
                                        <li className="pb-2">
                                          Certificado de defunción
                                        </li>
                                        <li className="pb-2">
                                          Registro de nacimiento del heredero
                                        </li>
                                        <li className="pb-2">
                                          Certificado de tradición y libertad
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvo predial/ valorización/
                                          área metropolitana según corresponda
                                        </li>
                                        <li className="pb-2">
                                          Copia de la escritura publica
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs17">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Venta de derechos gananciales
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Poder si así lo requiere
                                        </li>
                                        <li className="pb-2">
                                          Certificado de defunción
                                        </li>
                                        <li className="pb-2">
                                          Registro civil de Matrimonio
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>

                                <TabPane tabId="vTabs19">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Cancelación de patrimonio con menores de
                                        edad
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Solicitud de cancelación de patrimonio
                                        </li>
                                        <li className="pb-2">
                                          Recibo predial{" "}
                                        </li>
                                        <li className="pb-2">
                                          Copia de la escritura de constitución
                                          de patrimonio
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Fotocopia de la cedula de ciudadanía
                                          de los solicitantes
                                        </li>
                                        <li className="pb-2">
                                          Registro civil de nacimiento de los
                                          menores original
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs20">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Inventario de bienes de menor de edad
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Sin bienes:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">
                                          Registros originales del menor
                                        </li>
                                        <li className="pb-2">
                                          Copia de la cedula de ciudadanía del
                                          padre y de la persona con quien
                                          contraerá matrimonio
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Con bienes:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">
                                          Registro civil de nacimiento del menor
                                        </li>
                                        <li className="pb-2">
                                          Copia de la cedula de ciudadanía del
                                          padre y de la persona con quien
                                          contraerá matrimonio
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición del
                                          inmueble en copia
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvos predial según corresponda
                                          valorización área metropolitana
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs21">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Contrato de permuta
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Permutante 1</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Copia de cedulas de ciudadanía
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura de adquisición
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvos:
                                          <ul>
                                            <li className="pb-2">Predial</li>
                                            <li className="pb-2">
                                              Valorización
                                            </li>
                                            <li className="pb-2">
                                              Área metropolitana{" "}
                                            </li>
                                          </ul>
                                        </li>
                                        <li className="pb-2">
                                          Valor de la permuta
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Permutante 2</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Copia de cedulas de ciudadanía
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura de adquisición
                                        </li>
                                        <li className="pb-2">
                                          Paz y salvos:
                                          <ul>
                                            <li className="pb-2">Predial</li>
                                            <li className="pb-2">
                                              Valorización
                                            </li>
                                            <li className="pb-2">
                                              Área metropolitano
                                            </li>
                                          </ul>
                                        </li>
                                        <li className="pb-2">
                                          Valor de la permuta
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="hTabs4">
                        <Col md="12">
                          <Row>
                            <Col md="3">
                              <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                  <Nav
                                    className="flex-column nav-stacked text-left mt-4"
                                    role="tablist"
                                    tabs
                                  >
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "1"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("1");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-check mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal "
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Autenticación{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ fontSize: "14px" }}
                                        className={
                                          vTabs === "2"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("2");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-check mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Reconocimiento de firma y contenido{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "3"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("3");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-check mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Firma a ruego-personas que no{" "}
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "32px",
                                            marginTop: "-3px"
                                          }}
                                        >
                                          saben o no pueden{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "32px",
                                            marginTop: "-3px"
                                          }}
                                        >
                                          firmar
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              {/* Tab panes */}
                              <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Autenticación
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es cuando el notario da testimonio escrito
                                      de que las firmas que aparecen en un
                                      documento privado fueron puestas en su
                                      presencia, estableciendo la identidad de
                                      los firmantes y dando fe del día en que el
                                      hecho ocurrió.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Asistir personalmente a la notaría.
                                          Puede hacerse también adomicilio.
                                        </li>
                                        <li className="pb-2">
                                          Llevarla cédula de ciudadanía.
                                        </li>
                                        <li className="pb-2">
                                          Llevar el documento que se va a
                                          autenticar.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs2">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Reconocimiento de firma y contenido
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Quienes hayan firmado un documento privado
                                      podrán acudir ante el notario para
                                      declarar que las firmas son suyas y que el
                                      contenido del documento es cierto. La
                                      diligencia concluye con las firmas de los
                                      declarantes y del notario. De la veracidad
                                      de lo declarado responden los declarantes.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Asistir personalmente a la notaría.
                                        </li>
                                        <li className="pb-2">
                                          Llevarla cédula de ciudadanía.
                                        </li>
                                        <li className="pb-2">
                                          Llevar el documento que se va a
                                          reconocer.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs3">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Firma a ruego-personas que no saben o no
                                        pueden firmar
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Cuando se trate de personas que no sepan o
                                      no puedan firmar, en la diligencia se
                                      leerá en voz alta el documento que será
                                      firmado por un testigo presentado por el
                                      interesado. De esta diligencia, denominada
                                      'firma a ruego", el notario dejará
                                      constancia en el mismo documento. Para las
                                      personas con discapacidad visual o que no
                                      sepan leer y escribir, el notario leerá en
                                      voz alta el documento. Para las personas
                                      sordas, ellos mismos leerán el documento y
                                      expresarán su aprobación.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Asistir personalmente a la notaría.
                                          Excepcionalmente, puede hacerse fuera
                                          del despacho notarial.
                                        </li>
                                        <li className="pb-2">
                                          Llevar la cédula de ciudadanía.
                                        </li>
                                        <li className="pb-2">
                                          Llevar el documento privado que se va
                                          a firmar.
                                        </li>
                                        <li className="pb-2">
                                          Llevar el testigo cuando así se
                                          requiera.
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs4">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Permuta de Inmuebles
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es uno de los contratos para que una
                                      persona se convierta en propietaria de una
                                      vivienda, lote sin construcción o de una
                                      finca, entre otros bienes. Consiste, por
                                      ejemplo, en el intercambio de una vivienda
                                      0 de una finca por casas o cosas y dinero.
                                      Para que sea una permuta, el intercambio
                                      debe hacerse por un valor mayor en cosas
                                      que en dinero. Si el valores mayor en
                                      dinero, el contrato será de compraventa.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      Para realizar este contrato que se hace
                                      por medio de escritura pública, los
                                      interesados deben cumplir dos etapas:
                                    </p>
                                    <p className="text-justify font-weight-normal py-1">
                                      <strong>
                                        Primera etapa en la notaría:
                                      </strong>{" "}
                                      Hacer la escritura pública de permuta, en
                                      la que se especifiquen los linderos de los
                                      bienes que se intercambian y el precio de
                                      los mismos.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Documentos de identificación de las
                                          personas que hacen el contrato.
                                        </li>
                                        <li className="pb-2">
                                          Estas personas deben ser mayores de
                                          edad y por lo tanto están en capacidad
                                          de hacerlo.
                                        </li>
                                        <li className="pb-2">
                                          Certificados de paz y salvo por
                                          impuesto predial que se paga en la
                                          tesorería municipal y que contiene el
                                          avalúo catastral. Además, el paz y
                                          salvo por contribución de
                                          valorización, si aplica en el
                                          municipio. Si el inmueble está
                                          sometido a propiedad horizontal, se
                                          necesita también el certificado de paz
                                          y salvo por el pago de cuotas de la
                                          administración.
                                        </li>
                                        <li className="pb-2">
                                          Si alguno de quienes hacen el
                                          contrato, es persona jurídica, como
                                          una sociedad o un banco, debe
                                          presentarse el documento que acredite
                                          su existencia y representación legal.
                                        </li>
                                        <li className="pb-2">
                                          Se recomienda que se lleve al notario
                                          copia de la escritura pública o título
                                          de adquisición, junto con el
                                          certificado de libertad y tradición
                                          más reciente que le ha dado la Oficina
                                          de Registro de Instrumentos Públicos.
                                        </li>
                                        <li className="pb-2">
                                          Se sugiere que se presente copia de la
                                          parte del reglamento que se refiere a
                                          las propiedades que se permutan.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-1">
                                      <strong>
                                        Segunda etapa en la oficina de registro
                                        de instrumentos publicos:{" "}
                                      </strong>{" "}
                                      Una vez terminada la primera etapa, o sea,
                                      la de la escritura pública, el notario
                                      entrega copia auténtica a los interesados
                                      para llevarla a esta oficina.
                                    </p>
                                    <p className="text-justify font-weight-normal py-1">
                                      Para ser propietario de bienes inmuebles
                                      se requiere la escritura pública
                                      debidamente registrada donde consten la
                                      compraventa o la permuta. No olvide este
                                      requisito.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Si firman al tiempo los interesados, es
                                      mínimo ocho días hábiles, mientras el
                                      notario revisa la legalidad del contrato.
                                      firman al tiempo, puede superareste plazo.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="hTabs5">
                        <Col md="12">
                          <Row>
                            <Col md="3">
                              <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                  <Nav
                                    className="flex-column nav-stacked text-left mt-4"
                                    role="tablist"
                                    tabs
                                  >
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "1"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("1");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-clone mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Extra proceso o declaraciones bajo{" "}
                                          </span>{" "}
                                        </p>
                                        <p
                                          className=" font-weight-normal"
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "34px",
                                            marginTop: "-5px"
                                          }}
                                        >
                                          gravedad de juramento
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "2"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("2");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-clone mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Poder{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem style={{ marginTop: "8px" }}>
                                      <NavLink
                                        className={
                                          vTabs === "3"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("3");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-clone mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p style={{ marginBottom: "-0.5px" }}>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Promesa de Compraventa{" "}
                                          </span>{" "}
                                        </p>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal "
                                            style={{
                                              fontSize: "14px",
                                              paddingLeft: "33px"
                                            }}
                                          >
                                            {" "}
                                            de inmueble{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              {/* Tab panes */}
                              <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Extra proceso o declaraciones bajo
                                        gravedad de juramento
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Son declaraciones verbales o escritas
                                      presentadas ante el notario en las que se
                                      asegura bajo juramento la existencia de
                                      determinados hechos. La declaración verbal
                                      se transcribe en la notaría y la escrita
                                      la puede llevar el interesado.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Asistir personalmente a la notaría.
                                        </li>
                                        <li className="pb-2">
                                          Llevarla cédula de ciudadanía.
                                        </li>
                                        <li className="pb-2">
                                          Llevarla declaración escrita para
                                          revisión del notario o darla versión
                                          verbalmente.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Si. Según tarifa
                                      notarial. Las madres que acrediten su
                                      condición de cabeza de familia no tienen
                                      que pagar.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs2">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Poder
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es el acto mediante el cual una persona
                                      autoriza a otra para que la represente en
                                      la realización de un contrato o acto
                                      jurídico. En cada poder se debe
                                      especificar el propósito para el cual se
                                      otorga, es decir, debe contener la
                                      relación precisa de las facultades que se
                                      dan y para qué se dan a la persona que
                                      recibe el poder (apoderada). El poder
                                      puede constar en documento privado
                                      autenticado en su firma y reconocido en su
                                      contenido o en escritura pública.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          La persona que va a darel poderdebe
                                          asistira la notaría.
                                        </li>
                                        <li className="pb-2">
                                          Llevarla cédula de ciudadanía.
                                        </li>
                                        <li className="pb-2">
                                          Llevar el documento escrito para que
                                          el notario lo autentique y reconozca.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs3">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Promesa de Compraventa de Inmueble
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es un documento privado que realizan las
                                      partes para celebraren el futuro un
                                      contrato por escritura pública. La promesa
                                      de compraventa no hace propietario del
                                      inmueble al futuro comprador. Sólo se
                                      adquiere la propiedad con la escritura
                                      pública de compra debidamente registrada
                                      en la Oficina de Registro de Instrumentos
                                      Públicos.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          La persona debe ser mayordeedady por
                                          lo tanto está en capacidad de
                                          hacerlo.Lapersona debe ser
                                          mayordeedady por lo tanto está en
                                          capacidad de hacerlo.
                                        </li>
                                        <li className="pb-2">
                                          La promesa constará por escrito y debe
                                          contenerla fecha, hora y la notaría
                                          donde se firmará la escritura de
                                          compraventa.
                                        </li>
                                        <li className="pb-2">
                                          Las personas que firman la promesa de
                                          compraventa, deben estar de acuerdo
                                          con su contenido
                                        </li>
                                        <li className="pb-2">
                                          Lo que se promete comprar o vender
                                          debe estar permitido por la ley.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      El mismo día, si se cumplen todos los
                                      requisitos de ley.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="hTabs6">
                        <Col md="12">
                          <Row>
                            <Col md="3">
                              <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                  <Nav
                                    className="flex-column nav-stacked text-left mt-4"
                                    role="tablist"
                                    tabs
                                  >
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "1"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("1");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" nc-icon nc-touch-id mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Sucesión de bienes por causa de
                                            muerte{" "}
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "2"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("2");
                                        }}
                                      >
                                        <span className="float-left"> 
                                          <i className=" nc-icon nc-touch-id mr-3" aria-hidden="true" style={{fontSize:'22px'}}></i> 
                                        </span> 
                                        <p> <span className=" font-weight-normal" style={{fontSize:'14px'}}>Testamento</span> </p> 
                                        <div className="clearfix"></div>
                                      
                                      </NavLink>
                                    </NavItem> */}

                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "2"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("2");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" nc-icon nc-touch-id mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Testamento
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "3"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("3");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" nc-icon nc-touch-id mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Sucesiones
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={
                                          vTabs === "4"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("4");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" nc-icon nc-touch-id mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Adición a Sucesión
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              {/* Tab panes */}
                              <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Sucesión de bienes por causa de muerte
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es la que se hace para repartir los bienes
                                      del difunto, restando de ellos las deudas
                                      que tenía. Esta repartición se hace, una
                                      vez pagados los impuestos respectivos,
                                      entre los herederos del difunto, los
                                      acreedores y las demás personas que tengan
                                      interés en la misma según la ley. Este
                                      acto tiene un trámite especial, incluyendo
                                      edictos para informar al público sobre el
                                      inicio de la sucesión. El acto de la
                                      sucesión termina con la firma del notario
                                      en la escritura pública.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      Todos los interesados deben estar de
                                      acuerdo desde el comienzo hasta el final
                                      de la sucesión. En el caso de surgir un
                                      desacuerdo, la sucesión pasa a juzgado.
                                    </p>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Tanto el viudo o viuda, como todos los
                                          hijos y demás herederos e interesados,
                                          tienen que dar poder especial a un
                                          abogado para que adelante la
                                          diligencia. Este poder puede hacerse
                                          por escritura pública o por documento
                                          privado, en cuyo caso necesita de una
                                          autenticación especial llamada
                                          "reconocimiento de firma y contenido"
                                          y debe incluir la afirmación bajo
                                          juramento de la no existencia de otros
                                          herederos o interesados.
                                        </li>
                                        <li className="pb-2">
                                          Solicitud escrita por el abogado de la
                                          sucesión, junto con el inventario de
                                          bienes, la estimación de su valor y la
                                          repartición de los bienes entre los
                                          solicitantes.
                                        </li>
                                        <li className="pb-2">
                                          Si el difunto dejó bienes inmuebles se
                                          deben presentar los certificados de
                                          paz y salvo por impuesto predial. Si
                                          el inmueble es un apartamento o un
                                          local en un edificio, se necesita
                                          también el certificado de paz y salvo
                                          por el pago de la administración.
                                        </li>
                                        <li className="pb-2">
                                          Si en el inventario se incluyen
                                          deudas, debe presentarse el documento
                                          que lo pruebe.
                                        </li>
                                        <li className="pb-2">
                                          Copia auténtica del registro civil de
                                          defunción de la persona que falleció.
                                        </li>
                                        <li className="pb-2">
                                          Si el difunto fue casado se requiere
                                          presentar copia auténtica del registro
                                          civil de matrimonio, para liquidar
                                          primero la sociedad conyugal y
                                          entregarle al viudo o viuda lo que le
                                          corresponde. En caso de unión marital
                                          de hecho, se debe presentar la
                                          declaración respectiva.
                                        </li>
                                        <li className="pb-2">
                                          Si hubo hijos y todos están de acuerdo
                                          en llevar la sucesión ante notario,
                                          deben presentar sus registros civiles
                                          de nacimiento, para probar su calidad
                                          de herederos. Todos heredan por partes
                                          iguales si no hay testamento.
                                        </li>
                                        <li className="pb-2">
                                          Si el difunto hizo testamento abierto,
                                          debe presentarse copia auténtica de la
                                          escritura pública. Si hizo testamento
                                          cerrado o secreto, debe presentar
                                          copia auténtica de la escritura
                                          pública donde consta la diligencia de
                                          apertura.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Consulte con el notario, dado que el
                                      trámite de la sucesión tiene
                                      características particulares según os
                                      solicitantes, los bienes que se reparten y
                                      lo ordenado en el testamento, si lo hubo.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Si. Según tarifa
                                      notarial. Las madres que acrediten su
                                      condición de cabeza de familia no tienen
                                      que pagar.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs2">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Testamento
                                      </h5>
                                    </a>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Abierto:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Minuta del testador
                                        </li>
                                        <li className="pb-2">
                                          Cedula del testador
                                        </li>
                                        <li className="pb-2">
                                          Cedula de los testigos (3) junto con
                                          su estado civil, dirección y numero de
                                          contacto
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Cerrado:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Minuta</li>
                                        <li className="pb-2">
                                          Cedula del testador
                                        </li>
                                        <li className="pb-2">
                                          Cedula de los cinco (5) testigos junto
                                          con su estado civil, dirección y
                                          numero de contacto
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs3">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Sucesiones
                                      </h5>
                                    </a>

                                    <div className="text-justify font-weight-normal">
                                      <ul>
                                        <li className="pb-2">
                                          Registro de defunción causante
                                        </li>
                                        <li className="pb-2">
                                          Registro de matrimonio según
                                          corresponda
                                        </li>
                                        <li className="pb-2">
                                          Registro de nacimiento de herederos
                                        </li>
                                        <li className="pb-2">
                                          Poderes de cada uno de los interesados
                                        </li>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">Inventario</li>
                                        <li className="pb-2">Partición</li>
                                      </ul>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Documentos anexos de los activos
                                        Inmuebles:
                                      </strong>{" "}
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ul>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública de adquisición copia
                                          simple
                                        </li>
                                        <li className="pb-2">
                                          Copia de recibo predial
                                        </li>
                                        <li className="pb-2">
                                          <b>Paz y salvos:</b> predial,
                                          valorización, área metropolitana según
                                          corresponda
                                        </li>
                                      </ul>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Documentos anexos de los activos
                                        muebles:
                                      </strong>{" "}
                                      corresponda los documentos que los
                                      acrediten, por ejemplo:
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Certificado de las cuentas bancarias
                                        </li>
                                        <li className="pb-2">
                                          Copia de la tarjeta de propiedad de
                                          los vehículos
                                        </li>
                                        <li className="pb-2">
                                          Documentos que acrediten los pasivos,
                                          según el caso
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>

                                <TabPane tabId="vTabs4">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Adición a Sucesión
                                      </h5>
                                    </a>

                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">Solicitud</li>
                                        <li className="pb-2">Poder original</li>
                                        <li className="pb-2">
                                          Documentos del activo adicional, según
                                          corresponda
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="hTabs7">
                        <Col md="12">
                          <Row>
                            <Col md="3">
                              <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                  <Nav
                                    className="flex-column nav-stacked text-left mt-4"
                                    role="tablist"
                                    tabs
                                  >
                                    <NavItem style={{ marginBottom: "-13px" }}>
                                      <NavLink
                                        className={
                                          vTabs === "1"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("1");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-thumb-tack mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            Donación
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem style={{ marginBottom: "-13px" }}>
                                      <NavLink
                                        style={{ marginBottom: "10px" }}
                                        className={
                                          vTabs === "2"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("2");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-thumb-tack mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Constitución de Hipoteca
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem style={{ marginBottom: "-13px" }}>
                                      <NavLink
                                        style={{ marginBottom: "10px" }}
                                        className={
                                          vTabs === "3"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("3");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-thumb-tack mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Cancelación de Hipoteca
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ marginBottom: "10px" }}
                                        className={
                                          vTabs === "4"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("4");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-thumb-tack mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Afectación a vivienda familiar
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ marginTop: "-9px" }}
                                        className={
                                          vTabs === "5"
                                            ? "activo activeItem"
                                            : ""
                                        }
                                        onClick={() => {
                                          setVTabs("5");
                                        }}
                                      >
                                        <span className="float-left">
                                          <i
                                            className=" fa fa-thumb-tack mr-3"
                                            aria-hidden="true"
                                            style={{ fontSize: "22px" }}
                                          ></i>
                                        </span>
                                        <p>
                                          {" "}
                                          <span
                                            className=" font-weight-normal"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Patrimonio de Familia Inembargable
                                          </span>{" "}
                                        </p>
                                        <div className="clearfix"></div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              {/* Tab panes */}
                              <TabContent activeTab={"vTabs" + vTabs}>
                                <TabPane tabId="vTabs1">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Donación
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es uno de los contratos cuyo fin es que
                                      una persona se convierta en dueña de una
                                      vivienda, lote, finca, mueble o de una
                                      suma de dinero. cuando otra persona le
                                      regala una propiedad 0 parte de ella, y el
                                      que la recibe la acepta.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      Solo se hace por escritura pública, cuando
                                      se recibe un inmueble o parte de él o
                                      cuando lo que se recibe vale más de 50
                                      salarios mínimos mensuales.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      Cuando se va a hacer una donación que
                                      valga más de 50 salarios mínimos
                                      mensuales, hay que cumplir primero un
                                      requisito que se llama "insinuación de
                                      donación" , el cual se realiza en
                                      escritura pública en una notaría y en esa
                                      misma escritura se puede realizar la
                                      donación.
                                    </p>

                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          El que va a donar o regalar debe ser
                                          mayor de edad y por lo tanto está en
                                          capacidad de hacerlo. Si se trata de
                                          una donación a un menor, lo representa
                                          el padre o la madre, ambos o quien
                                          tiene la guarda.
                                        </li>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          interesados.
                                        </li>
                                        <li className="pb-2">
                                          Solicitud por escrito de quien hace la
                                          donación y de quien la recibe, ante el
                                          notario de la ciudad o municipio en
                                          donde vive el donante, con certificado
                                          del valor comercial de la propiedad
                                          que se va a donar, si es un inmueble.
                                          Para más información consulte al
                                          notario.
                                        </li>
                                        <li className="pb-2">
                                          Título de propiedad del bien que se va
                                          a donar, si es un inmueble.
                                        </li>
                                        <li className="pb-2">
                                          Certificados de paz y salvo por
                                          impuesto predial que contenga el
                                          avalúo catastral y por contribución de
                                          valorización, si aplica en el
                                          municipio. Si es un apartamento y
                                          forma parte de una propiedad
                                          horizontal, se necesita también el
                                          certificado de paz y salvo por el pago
                                          de la administración.
                                        </li>
                                        <li className="pb-2">
                                          Si alguno de los interesados es
                                          persona jurídica. como una sociedad,
                                          consulte al notario.
                                        </li>
                                        <li className="pb-2">
                                          La persona que hace la donación debe
                                          demostrar que queda con recursos
                                          suficientes para vivir y atender sus
                                          obligaciones.
                                        </li>
                                        <li className="pb-2">
                                          Se recomienda llevar al notario copia
                                          de la escritura pública o título de
                                          adquisición del inmueble que se va a
                                          regalar junto con el certificado de
                                          libertad y tradición más reciente que
                                          le ha dado la Oficina de Registro de
                                          Instrumentos Públicos.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      Una vez cumplidos los requisitos
                                      anteriores y se tenga la escritura
                                      pública, el notario entrega copia
                                      auténtica a los interesados para llevarla
                                      a la Oficina de Registro de Instrumentos
                                      Públicos.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>
                                        Requisitos para donación de bienes que
                                        valgan más de 50 salarios mínimos
                                      </strong>
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Mensuales: </strong> Consulte al
                                      notario.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Depende del cumplimiento de los
                                      requisitos. Consulte al notario.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Si. Según tarifa
                                      notarial. Las madres que acrediten su
                                      condición de cabeza de familia no tienen
                                      que pagar.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs2">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Constitución de Hipoteca
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Hay hipoteca cuando una persona, un banco
                                      o una entidad financiera (llamada
                                      acreedora) le presta a otra persona una
                                      suma de dinero y esta última (llamada
                                      deudora) asegura el pago, garantizándolo
                                      con un inmueble de su propiedad. Si el
                                      deudor incumple con los pagos (capital e
                                      intereses), el inmueble podrá ser
                                      embargado y rematado por un juez para
                                      pagarle a quien prestó el dinero. La
                                      hipoteca siempre se hace por escritura
                                      pública. Para hacer la hipoteca los
                                      interesados deben cumplir dos etapas:
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Primera etapa en la notaría
                                      </strong>
                                      Firmar (otorgar) la escritura pública de
                                      hipoteca, en la que aparece la descripción
                                      del inmueble que se da en garantía y la
                                      manifestación de que ese inmueble queda
                                      hipotecado a favor del acreedor.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Los interesados deben ser mayores de
                                          edad y por lo tanto, están en
                                          capacidad de hacerlo.
                                        </li>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          interesados. Si alguno de los
                                          interesados es persona jurídica, debe
                                          presentarse el documento que acredite
                                          su existencia y representación legal.
                                        </li>
                                        <li className="pb-2">
                                          Certificados de paz y salvo del
                                          impuesto predial según el valor
                                          catastral y de la contribución de
                                          valorización del inmueble que se
                                          hipoteca, si aplica en el municipio.
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad y tradición y
                                          copia de la escritura pública que
                                          demuestre que quien hipoteca es el
                                          dueño del bien.
                                        </li>
                                        <li className="pb-2">
                                          Se puede dar un poder para hacer una
                                          hipoteca. Consulte al notario.
                                        </li>
                                        <li className="pb-2">
                                          Casi siempre, la hipoteca se hace en
                                          la misma escritura de compraventa,
                                          pero puede hacerse por separado.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>
                                        Segunda etapa en la oficina de registro
                                        de instrumentos publicos:{" "}
                                      </strong>{" "}
                                      Una vez terminada la primera etapa, o sea,
                                      la de la escritura pública, el notario
                                      entrega copia auténtica a los interesados
                                      para llevarla a esa oficina, dentro de los
                                      noventa días hábiles siguientes a la fecha
                                      de la escritura. Olvidar este plazo,
                                      significa volver a iniciar el trámite de
                                      la hipoteca.
                                    </p>

                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Si firman al tiempo los interesados, es de
                                      mínimo ocho días hábiles mientras el
                                      notario revisa la legalidad del contrato.
                                      Si no firman al tiempo, puede superar este
                                      plazo.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs3">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Cancelación de Hipoteca
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Para cancelar una hipoteca, el dueño del
                                      bien hipotecado debe pagar la totalidad de
                                      la deuda al acreedor. Una vez ocurrido el
                                      pago, quien prestó el dinero (el acreedor)
                                      debe ir a la notaria para declarar que la
                                      deuda está pagada y que por ello cancela
                                      la hipoteca. Este acto de cancelación se
                                      hace también en escritura pública. El
                                      notario entrega un certificado que debe
                                      llevar el dueño del inmueble a la Oficina
                                      de Registro para que su bien quede sin
                                      hipoteca.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Si firman al tiempo los interesados, es de
                                      mínimo ocho días hábiles mientras el
                                      notario revisa la legalidad del contrato.
                                      Si no firman al tiempo, puede superar este
                                      plazo.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs4">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Afectación a Vivienda Familiar
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Esta protección la ordena la ley sobre la
                                      vivienda que habita la pareja casada o en
                                      unión marital durante al menos dos años y
                                      se llama comúnmente, la ly de la doble
                                      firma, porque para vender el inmueble
                                      protegido se requiere la firma de los dos.
                                      Además, solo puede ser embargado por el
                                      acreedor que figura en la escritura de
                                      hipoteca(Entidad financiera que realizó el
                                      préstamo para la adquisición de la
                                      vivienda, mientras haya deuda).
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      Los cónyuges o compañeros permanentes
                                      pueden decidir no someter su vivienda a
                                      esta protección. La afectación a vivienda
                                      familiar puede hacerse al momento de
                                      comprar la vivienda o tiempo después.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          cónyuges o compañeros permanentes.
                                        </li>
                                        <li className="pb-2">
                                          Si el dueño de la vivienda es uno de
                                          los cónyuges, para protegerla se
                                          necesita que firme el otro cónyuge o
                                          compañero permanente.
                                        </li>
                                        <li className="pb-2">
                                          Certificado de libertad de la
                                          vivienda.
                                        </li>
                                        <li className="pb-2">
                                          Para cancelar la afectación a vivienda
                                          familiar, se deben cumplir estos
                                          requisitos
                                        </li>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          cónyuges o compañeros permanentes.
                                        </li>
                                        <li className="pb-2">
                                          Escritura pública donde aparece la
                                          protección de la vivienda.
                                        </li>
                                        <li className="pb-2">Defunción.</li>
                                      </ol>
                                    </div>

                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Consulte con el notario, dado que el acto
                                      depende de la revisión que hace éste.
                                      llamada "control de legalidad"
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                                <TabPane tabId="vTabs5">
                                  <div className="article-content pt-2">
                                    <a href="javascrip: void(0);">
                                      <h5 className="font-weight-bold title">
                                        Patrimonio de Familia Inembargable
                                      </h5>
                                    </a>
                                    <p className="text-justify font-weight-normal">
                                      Es una clase especial de protección de la
                                      vivienda de una familia, que impide el
                                      embargo que pueda hacerle un acreedor al
                                      dueño de la propiedad. Además, evita que
                                      el propietario ponga en peligro el
                                      inmueble de la familia por un mal negocio.
                                      Si en el momento de la compra no se hizo
                                      esta protección, puede hacerse en
                                      cualquier momento. Solo puede ser
                                      embargado por el acreedor que figura en la
                                      escritura de hipoteca, esto es, por la
                                      entidad financiera o por la persona que
                                      realizó el préstamo para la adquisición de
                                      la vivienda, mientras haya deuda en mora.
                                      Cuando es una vivienda de interés social,
                                      es obligatorio por ley y se debe hacer en
                                      la misma escritura pública de compra de la
                                      vivienda.
                                    </p>
                                    <p className="text-justify font-weight-normal py-2">
                                      <strong>Requisitos:</strong>
                                    </p>
                                    <div className="text-justify font-weight-normal">
                                      <ol>
                                        <li className="pb-2">
                                          Documentos de identificación de los
                                          cónyuges o compañeros permanentes.
                                        </li>
                                        <li className="pb-2">
                                          Certificado de la Oficina de Registro
                                          de Instrumentos Públicos sobre la
                                          vivienda
                                        </li>
                                        <li className="pb-2">
                                          Copia auténtica del registro civil de
                                          los hijos menores que se benefician de
                                          la protección. Se beneficia también de
                                          esta protección el cónyuge o compañero
                                          permanente que no es dueño de la
                                          vivienda.
                                        </li>
                                        <li className="pb-2">
                                          Una vez hecha la escritura pública de
                                          patrimonio de familia inembargable,
                                          notario entrega la copia auténtica
                                          para llevarla a la Oficina de Registro
                                          d Instrumentos Públicos, dentro de los
                                          noventa días siguientes a la fecha de
                                          la escritura. Olvidar este plazo,
                                          significa volver a iniciar el trámite.
                                        </li>
                                      </ol>
                                    </div>
                                    <p className="text-justify font-weight-normal">
                                      Esta protección se puede cancelar, en la
                                      mayoría de los casos, cuando los hijos
                                      menores cumplen dieciocho años. Consulte
                                      con el notario.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>
                                        Tiempo estimado de entrega:
                                      </strong>{" "}
                                      Mínimo ocho días hábiles mientras el
                                      notario revisa la legalidad del contrato.
                                    </p>
                                    <p className="text-justify font-weight-normal">
                                      <strong>Costo:</strong> Sí. Según tarifa
                                      notarial.
                                    </p>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>  
                        </Col>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogPost;
