import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function InfoAdicional() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <colorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Información Adicional
            </h3>
            <center className="mt-5">
              <a href={require("assets/pdf/infoAdicional.pdf")} rel="noopener noreferrer" style={{textDecoration: "none !important"}} target="_blank">
                <img
                  alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Requisitos trámites notariales</h6>
              </a>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default InfoAdicional;
