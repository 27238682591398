import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function Organigrama() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif", marginTop: "4em"}}>
              Organigrama
            </h3>
            <Row className="mt-4">
              <Col className="col-12 text-center">
                <img
                  alt="Organimagra de la Notaría 10 de Bucaramanga"
                  className="img-rounded img-responsive"
                  src={require("assets/img/sections/organigrama.jpg")}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Organigrama;
