/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";
// core comments

const carouselItems = [
  {
   
    src: require("assets/img/presentation-page/examples/Fachada.jpg"),
    altText: "Notaría Décima",
    caption: "Notaría Décima",
  },
  {
    src: require("assets/img/presentation-page/examples/Oficina13.jpg"),
    altText: "Dr. FERNANDO  CORTES NIÑO, Notario Décimo.",
    caption: "Dr. FERNANDO  CORTES NIÑO, Notario Décimo.",
  },
  /* {
    src: require("assets/img/presentation-page/examples/Oficina12.jpg"),
    altText: "Despacho Notarial.",
    caption: "Despacho Notarial.",
  }, */
  {
    src: require("assets/img/presentation-page/examples/office5.jpg"),
    altText: "Sala de Conciliaciones.",
    caption: "Sala de Conciliaciones.",
  },
  {
    src: require("assets/img/presentation-page/examples/office10.jpg"),
    altText: "Oficina jefe jurídca, Dr. CAROLINA VARGAS",
    caption: "Oficina jefe jurídca, Dr. CAROLINA VARGAS",
  },
  {
    src: require("assets/img/presentation-page/examples/office1.jpg"),
    altText: "Sala de espera de lectura de escrituras",
    caption: "Sala de espera de lectura de escrituras",
  },
  {
    src: require("assets/img/presentation-page/examples/office8.jpg"),
    altText: "Oficina de jurídica, Dr. Carolina Breton",
    caption: "Oficina de jurídica, Dr. Carolina Breton",
  },
  {
    src: require("assets/img/presentation-page/examples/office9.jpg"),
    altText: "Autenticaciones",
    caption: "Autenticaciones",
  },
  {
    src: require("assets/img/presentation-page/examples/office3.jpg"),
    altText: "Sala de autenticaciones con digiturno",
    caption: "Sala de autenticaciones con digiturno",
  },
  {
     src: require("assets/img/presentation-page/examples/office14.jpg"),
     altText: "Ing Gil Maria Pinzon TESORERO",
     caption: "Ing Gil Maria Pinzon TESORERO",
   },
  {
    src: require("assets/img/presentation-page/examples/office15.jpg"),
    altText: "Asistente del notario y contabilidad.",
    caption: "Asistente del notario y contabilidad.",
  },
  {
    src: require("assets/img/presentation-page/examples/office16.jpg"),
    altText: "Registro civil y declaraciones extrajuicio",
    caption: "Registro civil y declaraciones extrajuicio",
  },
  {
    src: require("assets/img/presentation-page/examples/office2.jpg"),
    altText: "Sala de recepción de escrituras",
    caption: "Sala de recepción de escrituras",
  },
  {
    src: require("assets/img/presentation-page/examples/office11.jpg"),
    altText: "Salón de lectura de escrituras",
    caption: "Salón de lectura de escrituras",
  },
  {
    src: require("assets/img/presentation-page/examples/10bga3.png"),
    altText: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
    caption: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
  },
  {
    src: require("assets/img/presentation-page/examples/10bga2.png"),
    altText: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
    caption: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
  },
  {
    src: require("assets/img/presentation-page/examples/10bga4.png"),
    altText: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
    caption: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
  },
  {
    src: require("assets/img/presentation-page/examples/10bga5.png"),
    altText: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
    caption: "Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.",
  },
  {
    src: require("assets/img/presentation-page/examples/office4.jpg"),
    altText: "Salón de eventos.",
    caption: "Salón de eventos.",
  },
];

function PresentationHeader() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <div
        className="features-3"
        style={{ paddingBottom: "0px", paddingTop: "111px" }}
      >
        <Container>
          <Row>
            <Col md="6" sm="12">
              <Row>
                <Col md="12" sm="12" className="m-auto">
                  <div className="title-brand">
                    <div
                      className="type"
                      style={{
                        backgroundColor: "#64bb46",
                        marginRight: "32px",
                        marginTop: "56px"
                      }}
                    >
                      Bucaramanga
                    </div>
                  </div>
                </Col>
                <Col md="12" sm="12">
                  <div
                    style={{
                      margin: "2em 2em",
                      fontSize: "17px",
                      marginTop: "8em"
                    }}
                  >
                    <h1
                      className="text-center acomodoTitulo"
                      style={{ fontFamily: "'Archivo Black', sans-serif" }}
                    >
                      Notaría Décima
                    </h1>
                  </div>
                </Col>
                <Col md="12" sm="12">
                  <div style={{ marginBottom: "36px" }}>
                    <h5
                      className="description text-center acomodo"
                      style={{ fontFamily: "'Merriweather', sans-serif" }}
                    >
                      Te damos la bienvenida, mira los servicios notariales que
                      podemos ofrecerte
                    </h5>
                  </div>
                  <div>
                  <h6>Contador de visitas</h6>
                    <a href="https://www.contadorvisitasgratis.com" title="contador de visitas">
                      <img src="https://counter6.optistats.ovh/private/contadorvisitasgratis.php?c=euw6h4mgczru3jlnr9epxzfj9td9pyj6" border="0" title="contador de visitas" alt="contador de visitas"/>
                    </a>
                  </div>
                  <Row className="fila">
                    <Col lg="6" md="12" className="columUno">
                      <p
                        style={{
                          fontFamily: "'Merriweather', sans-serif",
                          fontSize: "13px"
                        }}
                        className="text-nowrap"
                      >
                        Cra 21 N° 22 - 30 Bucaramanga, Colombia
                      </p>
                      <p
                        style={{
                          fontFamily: "'Merriweather', sans-serif",
                          fontSize: "13px"
                        }}
                        className="text-nowrap"
                      >
                        +57 (7) 6421111 - +57 317 513 0082
                      </p>
                      <p
                        style={{
                          fontFamily: "'Merriweather', sans-serif",
                          fontSize: "13px"
                        }}
                      >
                        decimabucaramanga@supernotariado.gov.co
                      </p>
                      <p
                        style={{
                          fontFamily: "'Merriweather', sans-serif",
                          fontSize: "13px"
                        }}
                      >
                        notariadecimabucaramanga@gmail.com
                      </p>
                    </Col>
                    <Col lg="6" md="12" className="columDos">
                      <p
                        className="text-nowrap"
                        style={{
                          fontFamily: "'Merriweather', sans-serif",
                          fontSize: "13px"
                        }}
                      >
                        Horario de atención:
                      </p>
                      <p
                        className="text-nowrap"
                        style={{
                          fontFamily: "'Merriweather', sans-serif",
                          fontSize: "13px"
                        }}
                      >
                        {" "}
                        <br/>
                        Lunes a jueves 7:00am - 1:00 y de 2:00 - 5:00pm <br />
                        Viernes: 7:00am - 12:00 y de 2:00 - 5:00pm <br />
                        Sábados de turno: 8:00am a 12:00pm <br />                     
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md="6" sm="12">
              {/* <img
                    className="imgHeader"
                    alt="..."
                    src={require("assets/img/presentation-page/examples/Fachada.jpg")}
                  /> 
              <div
              
              >
                
              </div> */}
              <Card className="card-raised page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    
                    items={carouselItems}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {carouselItems.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <CarouselCaption
                        className="d-sm-block"
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev bg-success"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left " />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next bg-success"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </div>
    </>
  );
}

export default PresentationHeader;
