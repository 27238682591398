import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    TabContent,
    TabPane,
    Button,
} from "reactstrap";
// import Tramites from "/src/views/Molda/Tramites.js";
// core components

// sections for this page
// import Politica from "./Informacion/Informacion.js";


function Participa() {
    const [iconTabs, setIconTabs] = React.useState("1");
    // const [Modal3, setModal3] = React.useState(false);
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    // const toggle = () => setTooltipOpen(!tooltipOpen);

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <>
        <div className="section section-gray" id="cards">
            <Container className="tim-container">
                <div className="title">
                    <h1 className="text-center title" style={{ color: "#031425" }}>
                        <br></br>
                    </h1>
                    <h1 className="text-center title" style={{ color: "#031425", marginTop: "2em"}}>
                        PARTICIPA<br></br>
                    </h1>
                </div>
                <Row className="justify-content-center">
                    <Col md="10">
                        <Card>
                            <CardBody>
                                <p className="text-justify" style={{ fontSize: "14px", color:"#68696F", fontFamily:"serif" }}>
                                    El Menú Participa es una nueva categoría del menú destacado que deben crear las entidades y organismos obligados en
                                    el encabezado principal de su sitio web. Las autoridades deben publicar en el Menú Participa los contenidos de información
                                    sobre los espacios, mecanismos y acciones que permiten la participación ciudadana en el ciclo de la gestión pública,
                                    esto con el propósito de dinamizar la vinculación de la ciudadanía en las decisiones y acciones públicas durante el
                                    diagnóstico, formulación, implementación, evaluación y seguimiento a la gestión institucional a través de su sitio
                                    web institucional. Contiene la información sobre las posibilidades de interacción e intervención de los ciudadanos,
                                    usuarios y grupos de interés en las fases de formulación, ejecución, control y evaluación del ciclo de la gestión
                                    pública definidas en la normatividad general y en las disposiciones específicas para cada entidad, o que son diseñados
                                    proactivamente para facilitar el ejercicio de este derecho.* La estructura del Menú Participa debe contener seis secciones,
                                    así:
                                    <br></br>
                                    <a href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                        <small style={{ color: 'blue' }}>*  Departamento Administrativo de la Función Pública (2021). Lineamientos para publicar información
                                            en el menú participa sobre participación ciudadana en la gestión pública. Obtenido de:
                                            <a style={{ color: 'blue' }} href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                                https://www.funcionpublica.gov.co/documents</a></small>
                                    </a>
                                </p>
                                <TabContent
                                    className="text-center"
                                    activeTab={"iconTabs" + iconTabs}
                                >
                                    <TabPane tabId="iconTabs1">
                                        <Row>
                                            <Col md="2">
                                                <div className="info info-hover">
                                                    <Button
                                                    className="btn-round btn-sm btn-link btn-lg"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href='/participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas';
                                                    }}>
                                                        <div className="icon icon-secondary">
                                                            <i className="nc-icon nc-vector"></i>
                                                        </div>
                                                        <b style={{ fontSize: "9px", color: "gray" }}>Diagnóstico e Identificación de problemas</b>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="info info-hover">
                                                    
                                                    <Button
                                                    className="btn-round btn-sm btn-link btn-lg"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href='/planeacion-y-presupuesto-participativo';
                                                    }}>
                                                        <div className="icon icon-secondary">
                                                            <i className="nc-icon nc-credit-card"></i>
                                                        </div>
                                                        <b style={{ fontSize: "9px", color: "gray" }}>Planeación y presupuesto participativo</b>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="info info-hover">
                                                    
                                                    <Button
                                                    className="btn-round btn-sm btn-link btn-lg"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href='/consulta-ciudadana';
                                                    }}>
                                                        <div className="icon icon-secondary">
                                                            <i className="nc-icon nc-zoom-split"></i>
                                                        </div>
                                                        <b style={{ fontSize: "9px", color: "gray" }}>Consulta ciudadana</b>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="info info-hover">
                                                    
                                                    <Button
                                                    className="btn-round btn-sm btn-link btn-lg"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href='/rendicion-de-cuentas';
                                                    }}>
                                                        <div className="icon icon-secondary">
                                                            <i className="nc-icon nc-money-coins"></i>
                                                        </div>
                                                        <b style={{ fontSize: "9px", color: "gray" }}>Rendición de Cuentas</b>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="info info-hover">
                                                    
                                                    <Button
                                                    className="btn-round btn-sm btn-link btn-lg"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href='/control-social';
                                                    }}>
                                                        <div className="icon icon-secondary">
                                                            <i className="nc-icon nc-satisfied"></i>
                                                        </div>
                                                        <b style={{ fontSize: "9px", color: "gray" }}>Control Social</b>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="info info-hover">
                                                    <Button
                                                    className="btn-round btn-sm btn-link btn-lg"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href='/colaboracion-e-innovacion-abierta';
                                                    }}>
                                                        <div className="icon icon-secondary">
                                                            <i className="nc-icon nc-support-17"></i>
                                                        </div>
                                                        <b style={{ fontSize: "9px", color: "gray" }}>Colaboración e innovación abierta</b>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </>

    );

}

export default Participa;